import React, { useState, useEffect } from 'react';
import { apiUrl } from '../../config';
import { fetchData } from '../../utils/api';
import styles from '../../styles/scss/system/Reviews.module.scss';

function Reviews() {
  const [reviews, setReviews] = useState([]);
  const [answerInput, setAnswerInput] = useState('');
  const [activeReviewId, setActiveReviewId] = useState(null);
  const [statusFilter, setStatusFilter] = useState('0');

  useEffect(() => {
    fetchReviews();
  }, [statusFilter]);

  const fetchReviews = async () => {
    try {
        const token = localStorage.getItem('accessToken');
        const response = await fetchData(`${apiUrl}/api/sys/reviews?status=${statusFilter}`, "GET", token);
        const data = response
        if (data.request.status === 0) {
            setReviews(data.request.data);
        } else if (data.request.status === 1) {
          setReviews([])
        }
    } catch (error) {
        console.error('Error fetching reviews:', error);
    }
  };

  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleStatusUpdate = async (reviewId, newStatus) => {
    try {
        const token = localStorage.getItem('accessToken');
        const response = await fetchData(`${apiUrl}/api/sys/reviews/${reviewId}/status`, "POST", token, {body: {"status": newStatus} });

        fetchReviews(); // Перезагружаем отзывы после изменения статуса
        } catch (error) {
        console.error('Error updating review status:', error);
        }
  };


  const handleDeleteReview = async (reviewId) => {
    const isConfirmed = window.confirm('Вы уверены, что хотите удалить этот отзыв?');
    if (isConfirmed) {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await fetchData(`${apiUrl}/api/sys/reviews/${reviewId}/remove`, "GET", token);
        if (response.request.status === 0) {
          setReviews(reviews.filter(review => review.id !== reviewId));
          alert('Отзыв удален.')
        }
      } catch (error) {
        console.error('Error updating review status:', error);
        alert("Ошибка")
      }
    }
  };



  const handleSwitchReviewAnswer = (id) => {
    setActiveReviewId(id)
    setAnswerInput('');
  };
  const handleInputChange = (event) => {
    setAnswerInput(event.target.value);
  };
  const sendAnswer = async (reviewId) => {
    if (!reviewId) {
      alert('Ошибка, обновите страницу и попробуйте еще раз.')
    } else {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await fetchData(`${apiUrl}/api/sys/reviews/${reviewId}/answer`,
          "POST",
          token,
          {
            body: { answer: answerInput }
          })

        if (response.request.status === 0) {
          alert(response.request.message)
        } else {
          alert(response.request.message)
        }
      } catch (error) {
        alert("Произошла ошибка:\n1. Проверьте соединение с интернетом\n2. Проверьте обработчик на сервере")
      }
    }
  };
  return (
    <div className={styles.Reviews}>
      <h1>Отзывы</h1>
      <label>
        Фильтр по статусу:
        <select value={statusFilter} onChange={handleStatusChange}>
          <option value="">Все</option>
          <option value="0">Не опубликовано</option>
          <option value="1">Опубликовано</option>
        </select>
      </label>
      <ul>
        {reviews.map((review) => (
          <li key={review.id} className={styles.reviewItem}>
            <strong>{review.name}</strong><br />
            {review.message}<br />
            <small>Дата: {new Date(review.created_at).toLocaleString()}</small><br />

            <h3>Ответ на отзыв</h3>

            {/* Кнопка показа формы для ответа */}
            {activeReviewId !== review.id && (
              review.answer && (
                <p>{review.answer}</p>
              )
            )}
            {activeReviewId !== review.id && (
              review.answer !== null && review.answer.length === 0
              ? <button onClick={() => handleSwitchReviewAnswer(review.id)} type='button' className={`${styles.answerbtn} ${styles.button} ${styles.btnShowForm}`}>Написать ответ</button>
              : <button onClick={() => handleSwitchReviewAnswer(review.id)} type='button' className={`${styles.chancgeAnswerbtn} ${styles.button} ${styles.btnShowForm}`}>Изменить ответ</button>
            )}
            {activeReviewId === review.id && (
              <form className={styles.formAnswer}>
                <textarea onChange={handleInputChange} placeholder='Сообщение' defaultValue={review.answer}></textarea>
                <div>
                  <button onClick={() => sendAnswer(review.id)} type='button' className={`${styles.answerbtn} ${styles.button}`}>Ответить</button>
                </div>
              </form>
            )}

            <small className={`${styles.status} ${review.status === 1 ? styles.published : styles.unpublished}`}>
              Статус: {review.status === 1 ? 'Опубликовано' : 'Не опубликовано'}
            </small>
            
            <div className={styles.btns}>
              <button
                className={`${styles.button} ${review.status === 1 ? styles.unpublish : styles.publish}`}
                onClick={() => handleStatusUpdate(review.id, review.status === 1 ? '0' : '1')}
              >
                {review.status === 1 ? 'Снять с публикации' : 'Опубликовать'}
              </button>
              <button
                className={`${styles.button} ${styles.delete}`}
                onClick={() => handleDeleteReview(review.id)}
              >
                Удалить
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Reviews;