import React, { useState } from 'react';
import '../styles/css/styles-cms.css';

import SideBar from '../components/system/SideBar';
import Header from '../components/system/Header';
import MainContent from '../components/system/MainContent';
import OffMainContent from '../components/system/OffMainContent';
import Users from './system/Users';
import Rooms from './system/Rooms';
import ExpiredBookings from '../components/system/bookings/ExpiredBookings';
import CanceledBookings from '../components/system/bookings/CanceledBookings';
import OffExpiredBookings from '../components/system/offBookings/OffExpiredBookings';
import OffCanceledBookings from '../components/system/offBookings/OffCanceledBookings';
import ReceptionBookings from '../components/system/reception/ReceptionBookings';
import ReceptionConfirmBookings from '../components/system/reception/ReceptionConfirmBookings';
import ReceptionCanceledBookings from '../components/system/reception/ReceptionCanceledBookings';
import NoPaymentBookings from '../components/system/NoPaymentBookings';
import Reviews from '../components/system/Reviews';
import Customers from '../templates/system/Customers';

function System({ type }) {

  const [headerTitle, setHeaderTitle] = useState('')

  return (
    <React.Fragment>
      <div className='system-body'>
        <SideBar />
        <div className="system-main-content">
          <Header title={headerTitle} />
          {type === "reception" && <ReceptionBookings />}
          {type === "receptionConfirmes" && <ReceptionConfirmBookings />}
          {type === "receptionCanceledBookings" && <ReceptionCanceledBookings />}
          {type === "offMain" && <OffMainContent />}
          {type === "offExpiredBookings" && <OffExpiredBookings />}
          {type === "offCanceledBookings" && <OffCanceledBookings />}
          {type === "nopaybookings" && <NoPaymentBookings />}
          {type === "main" && <MainContent setHeaderTitle={setHeaderTitle} />}
          {type === "expiredBookings" && <ExpiredBookings setHeaderTitle={setHeaderTitle} />}
          {type === "canceledBookings" && <CanceledBookings setHeaderTitle={setHeaderTitle} />}
          {type === "users" && <Users />}
          {type === "reviews" && <Reviews />}
          {type === "rooms" && <Rooms setHeaderTitle={setHeaderTitle} />}
          {/* Main */}
          {type === "customers" && <Customers setHeaderTitle={setHeaderTitle} />}
        </div>
      </div>
    </React.Fragment>
  );
}

export default System;
