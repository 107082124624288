export const maxDaysBookingPeriod = 7
// Максимальная дата заезда (через 14 дней)
export const maxDaysBooking = 14
let minDateBookingVal = "2025-01-09";

// Получаем текущую дату
let today = new Date();
let todayStr = today.toISOString().split("T")[0]; // Преобразуем в формат YYYY-MM-DD

if (minDateBookingVal < todayStr) {
  // Устанавливаем завтрашнюю дату
  let nextDay = new Date(today);
  nextDay.setDate(nextDay.getDate() + 1);
  minDateBookingVal = nextDay.toISOString().split("T")[0];
}

export const minDateBooking = minDateBookingVal

export const maxDateBooking = "2025-02-28"
// Минимальная дата заезда (через 2 дня)
export const minDaysBooking = 1