import React, { useEffect, useState } from "react";
import "../../styles/scss/ReviewsComponent.scss";
import { fetchData } from "../../utils/api";
import { apiUrl } from "../../config";
import { useLanguage } from "../../LanguageContext";

const ReviewsFormComponent = () => {
  const [reviews, setReviews] = useState([]);
  const [formData, setFormData] = useState({ name: "", message: "" });
  const { language } = useLanguage();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.name && formData.message) {
      setReviews([...reviews, { ...formData, id: Date.now() }]);
      sendReview()
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const getMessageLang = (status, message) => {
    switch (language) {
      case 'tm':
        if (status === 0) {
          alert('Teswiriňiz üçin sag boluň!')
        } else if (status === 1) {
          alert('Siz eýýam ýakyn wagtlarda teswir galdyrdyňyz.')
        } else if (status === 2) {
          alert('Ýalňyş. Ýene-de bir gezek synanyşyp görmegiňizi haýyş edýäris.')
        } else if (status === 11) {
          alert('Hemme setirleri dolduryň.')
        } else if (status === 12) {
          alert('Adyňyz 12 harpdan köp bolmaly däl.')
        } else if (status === 13) {
          alert('Adyňyzda harpdan başga bellik bolmaly däl.')
        } else if (status === 14) {
          alert('Galdyran teswiriňiziň möçberi uly.')
        }
        break;
      case 'ru':
        if (status === 0) {
          alert('Спасибо за оставленный отзыв!')
        } else if (status === 1) {
          alert('Вы уже отправляли отзывы за последнее время.')
        } else if (status === 2) {
          alert('Ошибка. Пожалуйста попробуйте еще раз.')
        } else if (status === 11) {
          alert('Отсутствуют обязательные поля')
        } else if (status === 12) {
          alert('Имя должно содержать не более 12 символов')
        } else if (status === 13) {
          alert('Имя содержит недопустимые символы')
        } else if (status === 14) {
          alert('Сообщение слишком длинное')
        }
        break;
      case 'en':
        if (status === 0) {
          alert('Thank you for leaving a review!')
        } else if (status === 1) {
          alert('You have already submitted reviews recently.')
        } else if (status === 2) {
          alert('Sorry, please try again.')
        } else if (status === 11) {
          alert('Required fields are missing')
        } else if (status === 12) {
          alert('The name must be no more than 12 characters long.')
        } else if (status === 13) {
          alert('The name contains invalid characters')
        } else if (status === 14) {
          alert('The message is too long')
        }
        break;
    }
  }

  const sendReview = async () => {
    const token = localStorage.getItem('accessToken');
    const result = await fetchData(`${apiUrl}/api/client/reviews/add`, "POST", token, {body: formData})
    getMessageLang(result.request.status, result.request.message)
    if (result.request.status === 0) {
      setFormData({ name: "", message: "" });
    }
  }

  // Состояние для языка
  const langs = {
    'ru': {
      'title': "Оставьте свой отзыв",
      'urname': "Ваше имя",
      'message': "Ваш отзыв",
      'btn': "Оставить отзыв",
    },
    'tm': {
      'title': "Teswirleriňizi galdyryň",
      'urname': "Adyňyz",
      'message': "Teswiriňiz",
      'btn': "Pikir bölüşmek",
    },
    'en': {
      'title': "Leave your feedback",
      'urname': "Your Name",
      'message': "Your Feedback",
      'btn': "Leave feedback",
    }
  };


  return (
    <div className="reviews">
      <div className="reviews__container">
        <h2 className="reviews__title">{langs[language].title}</h2>
        <form className="reviews__form">
          <input
            type="text"
            name="name"
            required
            placeholder={langs[language].urname}
            value={formData.name}
            onChange={handleChange}
            className="reviews__input"
          />
          <textarea
            name="message"
            required
            placeholder={langs[language].message}
            value={formData.message}
            onChange={handleChange}
            className="reviews__textarea"
          ></textarea>
          <button type="button" className="reviews__button abutton" onClick={handleSubmit}>{langs[language].btn}</button>
        </form>

        {/* <div className="reviews__list">
          {reviews.map((review) => (
            <div key={review.id} className="reviews__item">
              <h3 className="reviews__name">{review.name}</h3>
              <p className="reviews__message">{review.message}</p>
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default ReviewsFormComponent;
