// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { LanguageProvider, useLanguage } from './LanguageContext';
import MaintenanceMode from './components/MaintenanceMode';
import System from './templates/System';
import PageNotFound from './templates/PageNotFound';
import Login from './components/system/Login';
import Protected from './components/system/Protected';

import Home from './templates/Home';
import AboutUs from './templates/AboutUs';
import Rooms from './templates/Rooms';
import RoomDetail from './templates/RoomDetail';
import Services from './templates/Services';
import Gallery from './templates/Gallery';
import Contacts from './templates/Contacts';
import ClientRegisterComponent from './components/authorization/ClientRegisterComponent';
import ClientBooksComponent from './components/client/ClientBooksComponent';
import ClientBookCancelComponent from './components/client/ClientBookCancelComponent';
import BookRooms from './templates/BookRooms';
import PaymentByUrl from './templates/PaymentByUrl';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import Reviews from './templates/Reviews/Reviews'

const AppContent = () => {

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      document.body.className = 'home-background';
    } else if (location.pathname === '/system') {
      document.body.className = 'system-background';
    }
  }, [location]);

  const hotelName = {
    tm: 'Berkarar otel',
    ru: 'Беркарар отель',
    en: 'Berkarar hotel'
  }

  const language = localStorage.getItem('language') || 'tm';

  // #todo
  const titles = {
    home: {
      tm: "Baş sahypa",
      ru: "Главная страница",
      en: "Home page"
    },
    aboutus: {
      tm: "Biz hakda",
      ru: "О нас",
      en: "About us"
    },
    services: {
      tm: "Hyzmatlar",
      ru: "Услуги",
      en: "Services"
    },
    gallery: {
      tm: "Galereýa",
      ru: "Галерея",
      en: "Gallery"
    },
    contacts: {
      tm: "Habarlaşmak",
      ru: "Контакты",
      en: "Contacts"
    },
    rooms: {
      tm: "Otaglar",
      ru: "Апартаменты",
      en: "Rooms"
    },
    room: {
      tm: "Otag",
      ru: "Апартаменты",
      en: "Room"
    },
    reviews: {
      tm: "Teswirler",
      ru: "Отзывы",
      en: "Reviews"
    },
    authorization: {
      tm: "Giriş",
      ru: "Вход",
      en: "Log in"
    },
    books: {
      tm: "Şahsy hasap",
      ru: "Личный кабинет",
      en: "Personal account"
    }
  }
  const getTitleLang = (page) => {
    const title = titles[page]?.[language]
    if (title) {
      return `${title} - ${hotelName[language]}`
    } else {
      return hotelName[language]
    }
  } 

  return (
    <LanguageProvider>
      <Routes>
        <Route path="/" element={
          <>
            <Helmet><title>{getTitleLang('home')}</title></Helmet>
            <Home />
          </>
        } />
        <Route path="/aboutus" element={
          <>
            <Helmet><title>{getTitleLang('aboutus')}</title></Helmet>
            <AboutUs />
          </>
        } />
        <Route path="/services" element={
          <>
            <Helmet><title>{getTitleLang('services')}</title></Helmet>
            <Services />
          </>
        } />
        <Route path="/gallery" element={
          <>
            <Helmet><title>{getTitleLang('gallery')}</title></Helmet>
            <Gallery />
          </>
        } />
        <Route path="/contacts" element={
          <>
            <Helmet><title>{getTitleLang('contacts')}</title></Helmet>
            <Contacts />
          </>
        } />

        <Route path="/rooms" element={
          <>
            <Helmet><title>{getTitleLang('rooms')}</title></Helmet>
            <Rooms />
          </>
        } />

        <Route path="/rooms/:id/:typePage" element={
          <>
            <Helmet><title>{getTitleLang('room')}</title></Helmet>
            <RoomDetail />
          </>
        } />
        <Route path="/authorization" element={
          <>
            <Helmet><title>{getTitleLang('authorization')}</title></Helmet>
            <ClientRegisterComponent />
          </>
        } />

        <Route
          path="/book-rooms"
          element={<Protected element={
            <>
              <Helmet><title>{getTitleLang('rooms')}</title></Helmet>
              <BookRooms />
            </>
        } requiredRoles={['client']}/>}
        />

        <Route path="/books" element={<Protected element={
          <>
            <Helmet><title>{getTitleLang('books')}</title></Helmet>
            <ClientBooksComponent />
          </>
        } requiredRoles={['client']} />} />

        <Route path="/reviews" element={
          <>
            <Helmet><title>{getTitleLang('reviews')}</title></Helmet>
            <Reviews />
          </>
        } />

        <Route path="/books/cancel/:id" element={<Protected element={<ClientBookCancelComponent />} requiredRoles={['client']} />} />

        <Route path="/f/payment/:book_number/:code" element={<PaymentByUrl />} /> {/* #todel */}

        <Route path="/login" element={<Login />} />
        {/* Reception bookings */}
        <Route path="/system/reception" element={<Protected element={<System type="reception" />} requiredRoles={['admin', 'reception']} />} />
        <Route path="/system/reception/confirmes" element={<Protected element={<System type="receptionConfirmes" />} requiredRoles={['admin', 'reception']} />} />
        <Route path="/system/reception/canceled-bookings" element={<Protected element={<System type="receptionCanceledBookings" />} requiredRoles={['admin', 'reception']} />} />
        {/* Offline Active bookings */}
        <Route path="/system/off" element={<Protected element={<System type="offMain" />} requiredRoles={['admin', 'booker']} />} />
        <Route path="/system/off/expired-bookings" element={<Protected element={<System type="offExpiredBookings" />} requiredRoles={['admin', 'booker']} />} />
        <Route path="/system/off/canceled-bookings" element={<Protected element={<System type="offCanceledBookings" />} requiredRoles={['admin', 'booker', 'reception']} />} />
        {/* Nopay bookings */}
        <Route path="/system/nopaybookings" element={<Protected element={<System type="nopaybookings" />} requiredRoles={['admin']} />} />
        {/* Active bookings */}
        <Route path="/system" element={<Protected element={<System type="main" />} requiredRoles={['admin', 'booker']} />} />
        {/* ecBookings bookings */}
        <Route path="/system/expired-bookings" element={<Protected element={<System type="expiredBookings" />} requiredRoles={['admin', 'booker']} />} />
        <Route path="/system/canceled-bookings" element={<Protected element={<System type="canceledBookings" />} requiredRoles={['admin', 'booker']} />} />
        <Route path="/system/rooms" element={<Protected element={<System type="rooms" />} requiredRoles={['admin', 'booker']} />} />
        <Route path="/system/reviews" element={<Protected element={<System type="reviews" />} requiredRoles={['admin']} />} />
        <Route path="/system/customers" element={<Protected element={<System type="customers" />} requiredRoles={['admin']} />} />
        <Route path="/system/users" element={<Protected element={<System type="users" />} requiredRoles={['admin']} />} />
        <Route path="/404" element={<PageNotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </LanguageProvider>

  );
};

const App = () => {
  const [isUnderMaintenance, setIsUnderMaintenance] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsUnderMaintenance(false), 5000);
    return () => clearTimeout(timer); // Clear timer on unmount
  }, []);

  return (
    <React.Fragment>
      {isUnderMaintenance ? (
        <MaintenanceMode />
      ) : (
        <Router>
          <HelmetProvider>
            <AppContent />
          </HelmetProvider>
        </Router>
      )}
    </React.Fragment>
  );
};

export default App;
