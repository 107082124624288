import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiUrl } from '../../config';
import axios from 'axios';

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const token = localStorage.getItem('accessToken');
  const role = localStorage.getItem('asd');
  if (token && (role === 'admin' || role === 'booker')) {
    window.location = '/system';
  }
  if (token && (role === 'reception')) {
    window.location = '/system/reception';
  }
  if (token && (role === 'client')) {
    window.location = '/404';
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiUrl}/api/login`, {
        username,
        password
      });
      const token = response.data.token;
      const asd = response.data.asd;
      localStorage.setItem('accessToken', token);
      localStorage.setItem('asd', asd);

      alert('Авторизация успешна');
      let url = '/system'
      if (asd === 'reception') {
        url = '/system/reception'
      }
      navigate(url);

      window.location.reload();
      // Перезагружаем страницу после перенаправления
    } catch (error) {
      alert('Ошибка авторизации');
    }
  };

  return (
    <div className='login-form-backg'>
      <form className="login-form" onSubmit={handleLogin}>
          <span className="input-span">
            <label htmlFor="username" className="login-label">Имя пользователя</label>
            <input type="text" name='username' value={username} onChange={(e) => setUsername(e.target.value)} /></span>
          <span className="input-span">
            <label htmlFor="password" className="login-label">Пароль</label>
            <input type="password" name='password' value={password} onChange={(e) => setPassword(e.target.value)} /></span>
          <button type="submit" className="submit">Войти</button>
        </form>
    </div>
  );
}

export default Login;
