import React, { useEffect, useState } from 'react';
import Dashboard from './Dashboard';

import { intervalUpdatingData, set_status, showStatusBooking } from '../../utils/dynamic';

import { fetchData, formatDate } from '../../utils/api';
import { apiUrl } from '../../config'
import BookingEdit from './bookings/BookingEdit'
import BookingView from './bookings/BookingView'
import { useLanguage } from '../../LanguageContext';

const MainContent = ({ setHeaderTitle }) => {

  React.useEffect(() => {
      setHeaderTitle('Активные брони.');
  }, [setHeaderTitle]);

  const { language } = useLanguage();
  const [bookings, setBookings] = useState([])
  const [bookingsMessage, setBookingsMessage] = useState([])
  const [messageBook, setMessageBook] = useState('')
  if (messageBook.length !== 0) {
    setTimeout(() => {
      setMessageBook('')
    }, 3000)
  }
  const [loading, setLoading] = useState(false); // состояние для отслеживания загрузки

  const [isEditOpen, setIsEditOpen] = useState(false); // состояние для показа формы редактирования записи
  const [isViewOpen, setIsViewOpen] = useState(false); // состояние для просмотра записи
  const [editData, setEditData] = useState([]); // состояние с данными для редактирования записи
  const [viewData, setViewData] = useState([]); // состояние с данными для просмотра записи

  const [searchQuery, setSearchQuery] = useState(''); // состояние для поиска

  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' }); // состояние для сортировки по дате заезда/выезда

  // Handle VIEW/EDIT/DELETE click
  const handleVEDNClick = (booking, action) => {
    switch (action) {
      case 'accept':
        setViewData(booking)
        break;
      case 'view':
        setViewData(booking)
        setIsViewOpen(true);
        break;
      case 'edit':
        setEditData(booking)
        setIsEditOpen(true);
        break;
      default:
        break
    }
  };
  const set_statusComp = async (bookingId, method) => {
    const res = await set_status(bookingId, method)
    if (res) {
      setMessageBook(res)
      fetchGAB();
    }
  }
  // Handle VIEW/EDIT closer
  const handleVENClose = () => {
    setIsViewOpen(false);
    setIsEditOpen(false);
  };

  const fetchGAB = async () => {
    try {
      const token = localStorage.getItem('accessToken');
      const data = await fetchData(`${apiUrl}/api/sys/get-active-bookings`, "GET", token)
      if (data.request.status === 0) {
        // Обработка данных и связывание booking с соответствующим room
        const processedData = data.request.data.booking.map((booking) => {
          const room = data.request.data.rooms.find((room) => room.id === booking.room_id);
          const customer = data.request.data.customers.find((customer) => customer.id === booking.customer_id);
          return {
            ...booking,
            room_type: room ? room.translations.room_type[language] : 'Неизвестно', // Тип номера
            price: room ? room.price : 'Неизвестно', // Цена за первые сутки номера
            book_price: data.request.data.book_price ? data.request.data.book_price : 'Неизвестно', // Цена за бронь
            passport_serial_number: customer ? customer.passport_serial_number : 'Неизвестно', // Серия паспорта
            card_number_old: customer ? customer.card_number : 'Неизвестно', // 4 цифры карты #tododel
            cardholdername_old: customer ? customer.cardholdername : 'Неизвестно', // 4 цифры карты #tododel
            full_name: customer ? customer.full_name : 'Неизвестно', // Полное
            email: customer ? customer.email : 'Неизвестно', // Почта
            phone_number: customer ? customer.phone_number : 'Неизвестно', // Номер тел
          };
        });
        setBookings(processedData);
        setLoading(false); // Останавливаем загрузку
      } else {
        setBookingsMessage(data.request.message);
      }
    } catch (error) {
      console.error("Ошибка:", error);
    }
  };
  useEffect(() => {
    fetchGAB();
    // Обновление данных каждые n секунд
    const interval = setInterval(() => {
      setLoading(true)
      setTimeout(() => {
        fetchGAB();
      }, 3000)
    }, intervalUpdatingData);
    // Очистка интервала при размонтировании компонента
    return () => clearInterval(interval);
  }, []);



  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredBookings = bookings.filter((booking) => 
    (booking.room_type && booking.room_type.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (booking.cardholdername && booking.cardholdername.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (booking.cardholdername_old && booking.cardholdername_old.toLowerCase().includes(searchQuery.toLowerCase())) || // #tododel
    (booking.phone_number && booking.phone_number.includes(searchQuery)) ||
    (booking.available_from && booking.available_from.includes(searchQuery)) ||
    (booking.available_to && booking.available_to.includes(searchQuery)) ||
    (booking.book_number && booking.book_number.includes(searchQuery))
  );
  const handleSort = (key) => {
    let direction = 'asc';
  
    // Меняем направление, если сортируем по тому же полю
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedAndFilteredBookings = [...filteredBookings].sort((a, b) => {
    if (sortConfig.key) {
        const dateA = new Date(formatDate(a[sortConfig.key]));
        const dateB = new Date(formatDate(b[sortConfig.key]));
        if (dateA < dateB) return sortConfig.direction === 'asc' ? -1 : 1;
        if (dateA > dateB) return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  // Функция для отображения стрелки
  const renderSortArrow = (key) => {
    if (sortConfig.key === key) {
        return sortConfig.direction === 'asc' ? '▲' : '▼';
    }
    return "▼"; // стрелка по умолчанию
  };

  return (
      <>
        <Dashboard />
        <section className="system-bookings-table">
          <h2 className='system-bookings-table__title'>Онлайн</h2>
          <h2 className='system-bookings-table__title'>Активные</h2>
          <div className='d-flex just-content-sb'>
            <div className='d-flex just-content-sb'>
              <input 
                  type="text" 
                  placeholder="Поиск по тел/имени..."
                  value={searchQuery} 
                  onChange={handleSearchChange} 
                  className="search-input search-field" 
              />
            </div>

            {loading && (
              <div className="loader">
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
              </div>
            )}
          </div>
          <h3 className='success-message'>{messageBook}</h3>

          <table className='system-table'>
            <thead className='system-thead'>
              <tr className='system-tr'>
                <th className='system-th search-field'>№</th>
                <th className='system-th search-field'>Тип номера</th>
                <th className='system-th'>Почта</th>
                <th className='system-th search-field'>Имя плательщика</th>
                <th className='system-th search-field'>Тел.</th>
                <th className='system-th system-th__cursor' onClick={() => handleSort('available_from')}>Дата заезда {renderSortArrow('available_from')}</th>
                <th className='system-th system-th__cursor' onClick={() => handleSort('available_to')}>Дата выезда {renderSortArrow('available_to')}</th>
                <th className='system-th'>Статус</th>
                <th className='system-th'>Общая сумма</th>
                <th className='system-th'>Действия</th>
                <th className='system-th'></th>
              </tr>
            </thead>
            <tbody className='system-tbody'>

            {sortedAndFilteredBookings && sortedAndFilteredBookings.length > 0 ? (
              <React.Fragment>
              {sortedAndFilteredBookings.map((booking, index) => (
                <tr className="system-tr" key={index + 1}>
                  <td className="system-td">{booking.book_number}</td>
                  <td className="system-td">{booking.room_type}</td>
                  <td className="system-td">{booking.email}</td>
                  {booking.cardholdername ? (
                    <td className="system-td">{booking.cardholdername}</td>
                  ) : (
                    <td className="system-td">{booking.cardholdername_old}</td>
                  )}
                  <td className="system-td">{booking.phone_number}</td>
                  <td className="system-td">{booking.available_from}</td>
                  <td className="system-td">{booking.available_to}</td>
                  <td className="system-td">{showStatusBooking(booking.status)}</td>
                  <td className="system-td">{booking.amount}</td>
                  <td className="system-td">
                    {booking.reception_status === 2 && (
                      <button className="system-booking-table-btn system-booking-table-btn-view" onClick={() => set_statusComp(booking.id, 'acceptReception')}>Подт. для ресепшн</button>
                    )}
                    <button className="system-booking-table-btn system-booking-table-btn-view" onClick={() => handleVEDNClick(booking, 'view')}>Посмотреть</button>
                    {/* <button className="system-booking-table-btn system-booking-table-btn-edit" onClick={() => handleVEDNClick(booking, 'edit')}>Изменить</button> */}
                  </td>
                  <td className="system-td">{booking.reception_status === 0 || booking.reception_status === 1 ? ("✅") : "❌"}</td>
                </tr>
              ))}
              </React.Fragment>
            ) : (
              <tr>
                <td className="system-td">{bookingsMessage}</td>
              </tr>
            )}
            </tbody>
          </table>
        </section>
        {isEditOpen && (
          <BookingEdit booking={editData} onClose={handleVENClose} />
        )}
        {isViewOpen && (
          <BookingView booking={viewData} onClose={handleVENClose} />
        )}
      </>
  );
};

export default MainContent;