import React, { useEffect, useState } from 'react';
import '../../styles/css/slider.css';

const slides = [
  { id: 1, image: './media/img/aboutus/aboutus-01.jpg' },
  { id: 2, image: './media/img/aboutus/aboutus-02.jpg' },
  { id: 3, image: './media/img/aboutus/aboutus-03.jpg' },
  { id: 4, image: './media/img/aboutus/aboutus-04.jpg' },
  { id: 5, image: './media/img/aboutus/aboutus-05.jpg' },
  { id: 6, image: './media/img/aboutus/aboutus-06.jpg' },
  { id: 7, image: './media/img/aboutus/aboutus-07.jpg' },
  { id: 8, image: './media/img/aboutus/aboutus-08.jpg' },
  { id: 9, image: './media/img/aboutus/aboutus-09.jpg' },
  { id: 10, image: './media/img/aboutus/aboutus-10.jpg' },
  { id: 11, image: './media/img/aboutus/aboutus-11.jpg' },
  { id: 12, image: './media/img/aboutus/aboutus-12.jpg' },
  { id: 13, image: './media/img/aboutus/aboutus-13.jpg' },
  { id: 14, image: './media/img/aboutus/aboutus-14.jpg' },
  { id: 15, image: './media/img/aboutus/aboutus-15.jpg' },
  { id: 16, image: './media/img/aboutus/aboutus-16.jpg' },
  { id: 17, image: './media/img/aboutus/aboutus-17.jpg' },
  { id: 18, image: './media/img/aboutus/aboutus-18.jpg' },
  { id: 19, image: './media/img/aboutus/aboutus-19.jpg' },
  { id: 20, image: './media/img/aboutus/aboutus-20.jpg' },
  { id: 21, image: './media/img/aboutus/aboutus-21.jpg' },
  { id: 22, image: './media/img/aboutus/aboutus-22.jpg' },
  { id: 23, image: './media/img/aboutus/aboutus-23.jpg' },
];
const SliderComponent = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000); // Slide changes every 4 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="slider">
      {slides.map((slide, index) => (
        <div
          key={slide.id}
          className={`slide ${index === currentSlide ? 'active' : ''}`}
          style={{ backgroundImage: `url(${slide.image})` }}
        >
        </div>
      ))}
    </div>
  )
}

export default SliderComponent;
