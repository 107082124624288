import { useNavigate } from 'react-router-dom';
import { apiUrl } from '../../config';
import { useEffect, useState } from 'react';
import useAuthRedirect from '../../utils/modules/useAuthRedirect'
import { useLanguage } from '../../LanguageContext';
import axios from 'axios';

const ClientRegisterComponent = () => {  
  const navigate = useNavigate();
  const [video, setVideo] = useState(null); // Видео
  const [isRegister, setIsLogin] = useState(false); // Состояние для переключения между логином и регистрацией
  const [loginData, setLoginData] = useState({
    'email': '',
    'password': '',
    'confirm_password': ''
  });
  const [message, setMessage] = useState('');

  // Проверка авторизации при загрузке компонента
  useAuthRedirect('client', '/', '/authorization');

  const handleToggle = () => {
    setIsLogin(!isRegister); // Переключение между логином и регистрацией
  };
  const { language } = useLanguage();
  // #todo
  // const errors_messages = {
  //   '0': {
  //     'Üstünlikli girdiňiz. Siziň poçtaňyza hasaba giriş şahsy maglumatly hat goýberildi.',
  //     ''
  //   },
  //   '7': 'Nädogry poçta formaty.',
  // }

  const validInputsReq = (error_id) => {
    switch (error_id) {
      case 0:
        if (language === 'tm') {
          alert('Üstünlikli girdiňiz. Siziň poçtaňyza hasaba giriş şahsy maglumatly hat goýberildi.')
        }
        if (language === 'ru') {
          alert('Вам на почту отправлено письмо c вашими учетными данными.')
        }
        break;
      case 7:
        if (language === 'tm') {
          setMessage('Nädogry poçta formaty.')
        }
        if (language === 'ru') {
          setMessage('Не верный формат почты.')
        }
        break;
      case 3:
        if (language === 'tm') {
          setMessage('Siziň ýazan poçtaňyz eýýäm ullanylýar.')
        }
        if (language === 'ru') {
          setMessage('В системе уже есть запись введенной только что вами почтой. Похоже вы уже регистрировались.')
        }
        break;
      case 4:
        if (language === 'tm') {
          setMessage('Gizlin sözüňiz azyndan 5 harp ya-da sandan (1@!.) ybarat bolmaly.')
        }
        if (language === 'ru') {
          setMessage('Пароль должен иметь как минимум 5 символов')
        }
        break;
      case 5:
        if (language === 'tm') {
          setMessage('Gizlin sözüňiz deň gelenok.')
        }
        if (language === 'ru') {
          setMessage('Пароли не совпадают')
        }
        break;
      case 6:
        if (language === 'tm') {
          setMessage('Hasaba üstünlikli girdiňiz.')
        }
        if (language === 'ru') {
          setMessage('Регистрация прошла успешно')
        }
        break;
    }
  }

  const client_register = async () => {
    if (language === 'tm') {
      setMessage('Garaşyň...');
    } else if (language === 'ru') {
      setMessage('Пожалуйста подождите...');
    }
    try {
      // Логика для отправки данных на сервер
      const response = await fetch(`${apiUrl}/api/client-register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginData), // Убедитесь, что loginData правильно формируется
      });
  
      const result = await response.json();
      validInputsReq(result.request.status)
      client_login()

    } catch (error) {
      console.error('Ошибка при обработке запроса:', error);
      setMessage('Ошибка при обработке запроса.');
    }
  };


  const validInputsReqLogin = (error_id, token, asd) => {
    switch (error_id) {
      case 0:
        if (language === 'tm') {
          alert('Hasaba üstünlikli girdiňiz.')
        }
        if (language === 'ru') {
          alert('Успешный вход.')
        }
        localStorage.setItem('accessToken', token);
        localStorage.setItem('asd', asd);
        navigate('/');
        break;
      case 1:
        if (language === 'tm') {
          setMessage('Hasaba giriş şahsy maglumatyňyz ýalňyş.')
        }
        if (language === 'ru') {
          setMessage('Не верные учетные данные')
        }
        break;
    }
  }

  const client_login = async () => {
    if (language === 'tm') {
      setMessage('Garaşyň...');
    } else if (language === 'ru') {
      setMessage('Пожалуйста подождите...');
    }
    try {
      // Логика для отправки данных на сервер
      const response = await fetch(`${apiUrl}/api/client-login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginData), // Убедитесь, что loginData правильно формируется
      });
  
      const result = await response.json();
      validInputsReqLogin(result.request.status, result.request.token, result.request.asd)
    } catch (error) {
      console.error('Ошибка при обработке запроса:', error);
      setMessage('Ошибка при обработке запроса.');
    }
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // if (isRegister) {
    //   setLoginData((prev) => {
    //     const updatedData = { ...prev, [name]: value };
    
    //     // Проверка на минимальную длину пароля
    //     if (name === 'password' && value.length < 5) {
    //       setMessage('Пароль должен иметь как минимум 5 символов');
    //     } else if (name === 'confirm_password' || name === 'password') {
    //       // Проверка на совпадение паролей
    //       if (updatedData.password !== updatedData.confirm_password) {
    //         setMessage('Пароли не совпадают');
    //       } else {
    //         setMessage(''); // Сброс ошибки, если всё совпадает
    //       }
    //     }
    
    //     return updatedData;
    //   });
    // } else {
      setLoginData((prev) => ({ ...prev, [name]: value }));
    // }

  };


  useEffect(() => {
      // Запрос к API для получения списка видео
      axios
        .get(`${apiUrl}/api/client/get-manual-video`)
        .then((response) => {
          setVideo(response.data);
        })
        .catch((error) => {
          console.error("Ошибка при получении видео:", error);
        });
  }, []);
  const langs = {
    'ru': {
      'title': "Регистрация",
      'title_2': "Вход",
      'email': "Электронная почта",
      'email_placeholder': "Введите действующую эл. почту",
      'password': "Пароль",
      'password_placeholder': "Придумайте пароль",
      'passwordc': "Подтвердите пароль",
      'passwordc_placeholder': "Введите пароль еще раз",
      'login_btn': "Войти",
      'register_btn': "Регистрация",
      'create_acc': "Нет аккаунта?", 
      'login_acc': "У вас уже есть аккаунт?", 
    },
    'tm': {
      'title': "Hasaba al",
      'title_2': "Girmek uçin",
      'email': "Elektron poçta",
      'email_placeholder': "Işjeň elektron poçtaňyzy giriziň",
      'password': "Parol",
      'password_placeholder': "Parol oýlap tapyň",
      'passwordc': "Paroly tassyklanyň",
      'passwordc_placeholder': "Paroly ýene giriziň",
      'login_btn': "Girmek",
      'register_btn': "Hasaba al",
      'create_acc': "Hasabyňyz ýokmy?", 
      'login_acc': "Hasabyňyz barmy?", 
    },
    'en': {
      'title': "Registration",
      'title_2': "Sign in",
      'email': "Email",
      'email_placeholder': "Enter a valid email address",
      'password': "Password",
      'password_placeholder': "Create a password",
      'passwordc': "Confirm Password",
      'passwordc_placeholder': "Enter the password again",
      'login_btn': "Login",
      'register_btn': "Register",
      'create_acc': "Don't have an account?", 
      'login_acc': "Do you have an account?", 
    }
  };
  
  return (
    <div className="background-floor">
      <div className='block-form-login-manual'>
        <form className="form-simple container">
          {isRegister ? (
            <>
            <h4 className='form-simple__title'>{langs[language].title}</h4>
            {/* <!-- Электронная почта --> */}
            <div className="form-group">
              <label htmlFor="email">{langs[language].email}:</label>
              <input type="email" placeholder={langs[language].email_placeholder} value={loginData.email} id="email" name="email" required onChange={handleInputChange} />
            </div>

            <div className="form-group">
              <label htmlFor="password">{langs[language].password}</label>
              <input type="password" placeholder={langs[language].password_placeholder} value={loginData.password} id="password" name="password" required onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label htmlFor="confirm_password">{langs[language].passwordc}</label>
              <input type="password" placeholder={langs[language].passwordc_placeholder} value={loginData.confirm_password} id="confirm_password" name="confirm_password" required onChange={handleInputChange} />
            </div>

            <h3 className='error-message'>{message && message}</h3>
            {/* <!-- Кнопка для подтверждения бронирования --> */}
            <button type="button" className="button-booking" onClick={() => client_register()}>
              {langs[language].register_btn}
            </button>
            <p className='toggleClientForm'>{langs[language].login_acc} <span onClick={handleToggle}>{langs[language].login_btn}</span></p>
            </>
          ) : (
            <>
            <h4 className='form-simple__title'>{langs[language].title_2}</h4>
            {/* <!-- Электронная почта --> */}
            <div className="form-group">
              <label htmlFor="email">{langs[language].email}:</label>
              <input type="email" placeholder={langs[language].email_placeholder} value={loginData.email} id="email" name="email" required onChange={handleInputChange} />
            </div>

            <div className="form-group">
              <label htmlFor="password">{langs[language].password}</label>
              <input type="password" placeholder={langs[language].password} value={loginData.password} id="password" name="password" required onChange={handleInputChange} />
            </div>

            <h3 className='error-message'>{message && message}</h3>
            {/* <!-- Кнопка для подтверждения бронирования --> */}
            <button type="button" className="button-booking" onClick={() => client_login()}>
              {langs[language].login_btn}
            </button>
            <p className='toggleClientForm'>{langs[language].create_acc} <span onClick={handleToggle}>{langs[language].register_btn}</span></p>
            </>
          )}

        </form>

        {video &&
          <div className="block-form-login-manual__block">
              <div className="">
                  <video controls controlsList="nodownload">
                      <source src={`${apiUrl}${video.url}`} type="video/mp4" />
                      Ваш браузер не поддерживает видео тег. Пожалуйста обновите браузер или попробуйте другой.
                  </video>
              </div>
          </div>
        }
      </div>
    </div>
  )
}

export default ClientRegisterComponent;