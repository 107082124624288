import React, { useEffect, useState } from 'react';
import { apiUrl, domainFrontend } from '../../../config'
import { fetchData, formatDate } from '../../../utils/api';
import { boolElemByRole, formatDateTime, set_status, showTypeBooking } from '../../../utils/dynamic';
import { useLanguage } from '../../../LanguageContext';


const BookingView = ({ booking, onClose }) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const { language } = useLanguage();
    const [rooms, setRooms] = useState([])
    const [bookingStatus, setBookingStatus] = useState([])
    const [bookingStatusMessage, setBookingStatusMessage] = useState('')
    const [selectedPrice, setSelectedPrice] = useState('')
    const [messageStatusBook, setMessageStatusBook] = useState('')
    const [viewBooking] = useState({
        ...booking,
        available_from: formatDate(booking.available_from),
        available_to: formatDate(booking.available_to)
    });
    async function getRooms() {
        try {
            const token = localStorage.getItem('accessToken');
            const rooms = await fetchData(`${apiUrl}/api/sys/get-rooms-by-season`, 'GET', token)
            if (rooms.request.status === 0) {
                setRooms(rooms.request.data)
            }
        } catch (error) {
            console.error("Не удалось получить данные о номерах:", error);
        }
    }
    const handleSendMail = async (bookingId) => {
        setIsDisabled(true)
        try {
            const token = localStorage.getItem('accessToken');
            const res = await fetchData(`${apiUrl}/api/sys/send-mail-booktu?id=${bookingId}`, "GET", token)
            setMessageStatusBook(res.request.message)
        } catch (error) {
            console.error('Ошибка:', error)
        }
        setTimeout(() => setIsDisabled(false), 3000);
    }
    useEffect(() => {
        getStatus()
        getRooms()
    }, [])
    useEffect(() => {
        // Проверяем, есть ли данные rooms и установлен ли room_id
        if (rooms && rooms.length > 0 && viewBooking.room_id) {
            const selectedRoom = rooms.find((room) => room.id === parseInt(viewBooking.room_id));
            setSelectedPrice(selectedRoom ? selectedRoom.price : '');
        }
    }, [rooms, viewBooking.room_id])
    const set_statusComp = async (bookingId, method) => {
        const res = await set_status(bookingId, method)
        if (res) {
            setMessageStatusBook(res)
        }
    }

    const paymentLink = `${domainFrontend}/f/payment/${viewBooking.book_number}/${viewBooking.payment_code_by_url}`
    const copyToClipboard = () => {
        navigator.clipboard.writeText(paymentLink)
          .then(() => alert('Ссылка скопирована!'))
          .catch(err => console.error('Ошибка при копировании:', err));
    };

    const getStatus = async () => {
        const token = localStorage.getItem('accessToken');
        try {
            const result = await fetchData(`${apiUrl}/api/sys/get-status-order/${viewBooking.id}`, "GET", token)
            if (result.request.status === 0) {
                setBookingStatus(result.request.data)
            } else {
                setBookingStatusMessage(result.request.message)
            }
        } catch {}
    }


    return (
        <div className='system-background-hide'>
            <section className="system-container system-container-form">
                <div className='system-container__inner'>
                    <header className="system-header">Просмотр</header>
                    <div className="system-form">
                        {boolElemByRole(['admin']) &&
                        <div className="system-column">
                            <div className="system-input-box">
                                <label>Статус от банка</label>
                                <p>
                                    {bookingStatus.ErrorMessage}
                                </p>
                                <p>
                                    Статус заказа: {bookingStatus.OrderStatus}
                                </p>
                            </div>
                        </div>
                        }
                        {boolElemByRole(['admin', 'booker']) &&
                        <div className="system-column">
                            <div className="system-input-box">
                                <label>Тип брони</label>
                                <p>
                                    {showTypeBooking(viewBooking.type)}
                                </p>
                            </div>
                        </div>
                        }
                        <div className="system-column">
                            <div className="system-input-box">
                                <label>Статус оплаты</label>
                                <p>
                                    {viewBooking.type === 1 || viewBooking.type === 2 ? (
                                        viewBooking.payment_at ? (
                                            `Оплачено: ${formatDateTime(viewBooking.payment_at)}`
                                        ) : (
                                            "Не оплачено."
                                        )
                                    ) : (
                                        viewBooking.status === 2
                                        ? "Не оплачено"
                                        : viewBooking.payment_at
                                            ? formatDateTime(viewBooking.payment_at)
                                            : formatDateTime(viewBooking.created_at)
                                    )}
                                </p>
                            </div>
                        </div>
                        <div className="system-column">
                            <div className="system-input-box">
                                <label>Бронь добавлена в</label>
                                <p>
                                    {formatDateTime(viewBooking.created_at)}
                                </p>
                            </div>
                        </div>
                        <div className="system-column">
                            <div className="system-input-box">
                                <label>Общая сумма</label>
                                <p>
                                    {viewBooking.amount} ТМТ
                                </p>
                            </div>
                        </div>
                        
                        {boolElemByRole(['admin', 'booker']) &&
                            ((viewBooking.type === 2 && viewBooking.status == 0) &&
                                <div className="system-input-box">
                                    <label>Ссылка для оплаты</label>
                                    <input readOnly placeholder="Ссылка для оплаты" className='cursor-pointer hover-fun' value={paymentLink} onClick={copyToClipboard} type="text" name='book_number' />
                                </div>
                            )
                        }

                        <div className="system-input-box">
                            <label>Номер брони</label>
                            <input disabled placeholder="Номер брони" value={viewBooking.book_number} type="text" name='book_number' />
                        </div>
  
                        <div className="system-input-box">
                            <label>Телефон</label>
                            <input disabled placeholder="" value={viewBooking.phone_number || ''} type="telephone" name="phone_number" />
                        </div>

                        <div className='booking-form__guests'>
                            {viewBooking.guests && viewBooking.guests.map((guest, index) => (
                            <div key={index}>
                                <div className="system-input-box">
                                    <label>#{1+index} Полное имя гостя</label>
                                    <input disabled placeholder="Не обязательно" value={guest.full_name || ""} type="text" />
                                </div>
                                <div className="system-input-box">
                                    <label>Серийный номер паспорта</label>
                                    <input disabled placeholder="Не обязательно" value={guest.passport_serial_number || ""} type="text" />
                                </div>
                            </div>
                            ))}
                        </div>
                        {viewBooking.cardholdername ? (
                        <div className="system-input-box">
                            <label>Полное имя плательщика</label>
                            <input disabled placeholder="" value={viewBooking.cardholdername  || ''} type="text" name='cardholdername' />
                        </div>
                        ) : (
                        <div className="system-input-box">
                            <label>Полное имя плательщика</label>
                            <input disabled placeholder="" value={viewBooking.cardholdername_old  || ''} type="text" name='cardholdername_old' />
                        </div>
                        )}
                        {boolElemByRole(['admin', 'booker']) ? (
                        viewBooking.card_number ? (
                            <div className="system-input-box">
                                <label>4 последние цифры банковской карты</label>
                                <input disabled placeholder="" value={viewBooking.card_number || ''} type="text" name='card_number' />
                            </div>
                        ) : (
                            <div className="system-input-box">
                                <label>4 последние цифры банковской карты</label>
                                <input disabled placeholder="" value={viewBooking.card_number_old || ''} type="text" name='card_number_old' />
                            </div>
                        )):(null)}

                        <div className="system-input-box">
                            <label>Почта</label>
                            <input disabled placeholder="" value={viewBooking.email} type="telephone" name="email" />
                        </div>

                        <div className="system-column">
                            <div className="system-input-box">
                                <label>Начало брони</label>
                                <input disabled value={viewBooking.available_from} placeholder="Заезд" type="date" name='available_from' />
                            </div>
                            <div className="system-input-box">
                                <label>Окончание брони</label>
                                <input disabled value={viewBooking.available_to} placeholder="Выезд" type="date" name='available_to' />
                            </div>
                        </div>
                        <div className="system-input-box system-address">
                            <label>Тип номера</label>
                            <div className="system-column">
                                <div className="system-select-box">
                                    <select disabled value={viewBooking.room_id || ''} name='room_id'>
                                        {rooms && rooms.length > 0 ? (
                                            rooms.map((room) => (
                                                <option key={room.id} value={room.id}>{room.translations.room_type[language]}</option>
                                            ))
                                        ) : (
                                            <option value="">Нет номеров</option>
                                        )}
                                    </select>
                                </div>
                                <input disabled placeholder={selectedPrice || "Цена не доступна"} type="text" />
                            </div>
                        </div>

                        <div className='flex-btns'>
                            {boolElemByRole(['admin', 'booker']) && (
                                <>
                                {(booking.status === 0 || booking.status === 1) &&
                                    <button className="system-booking-table-btn system-booking-table-btn-cancel" onClick={() => set_statusComp(booking.id, 'cancelBook')}>Отменить бронь</button>
                                }
                                {booking.status === 0 && (
                                    (viewBooking.email &&
                                        <button className="system-booking-table-btn system-booking-table-btn-view" disabled={isDisabled} onClick={() => handleSendMail(viewBooking.id)}>Отправить данные пользователю</button>
                                    )
                                )}
                                {(viewBooking.type === 1 && viewBooking.status === 1) && (
                                    <button className="system-booking-table-btn system-booking-table-btn-view" onClick={() => set_statusComp(booking.id, 'acceptPaymentByBlank')}>Подтвердить оплату</button>
                                )}
                                </>
                            )}
                            {boolElemByRole(['admin', 'reception']) ? (
                                viewBooking.reception_status === 1 && viewBooking.status === 0 || viewBooking.status === 1 ? (
                                    <button type='button' className="system-booking-table-btn system-booking-table-btn-view" onClick={() => set_statusComp(viewBooking.id, 'acceptReceptionProgram')}>Подтвердить бронь</button>
                                ) : (null) ||
                                viewBooking.reception_status === 0 && viewBooking.status === 0 || viewBooking.status === 1 ? (
                                    <button type='button' className="system-booking-table-btn system-booking-table-btn-cancel" onClick={() => set_statusComp(viewBooking.id, 'cancelReceptionProgram')}>Отменить подтверждение</button>
                                ) : (null)
                            ) : (null)}
                            <button type='button' className="system-booking-table-btn system-booking-table-btn-cancel" onClick={onClose}>Закрыть</button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default BookingView;