import { apiUrl } from "../config";

// данные для динамичности
export const intervalUpdatingData = 7000 // Интервал в мс для обновления данных

export const boolElemByRole = (roles = ['hadmin']) => { 
    const userRole = localStorage.getItem('asd'); // Получаем роль из localStorage

    // Проверяем, содержится ли роль пользователя в массиве `roles`
    return roles.includes(String(userRole));
};

export const showStatusBooking = (status) => {
    switch (status) {
        case 0:
            return "Оплачено" 
        case 1:
            return "Не оплачено"
        case 2:
            return "Ожидается"
        case 3:
            return "Отменено"
        default:
            break
    }
}

export const showTypeBooking = (type) => {
    switch (type) {
        case 0:
            return "Онлайн" 
        case 1:
            return "Бланком"
        case 2 || 3:
            return "Ссылкой"
        default:
            break
    }
}


export const set_status = async (bookginId, method) => {
    let isConfirmed = ''
    if (method === 'cancelBookFull' || method === 'cancelBook' || method === 'cancelReceptionProgram') {
        isConfirmed = window.confirm('Подтвердить отмену брони?');
    } else if (method === 'acceptReception' || method === 'acceptReceptionProgram') {
        isConfirmed = window.confirm('Подтвердить бронь?');
    } else if (method === 'acceptPayment') {
        isConfirmed = window.confirm('Подтвердить то, что бронь оплачена?');
    } else if (method === 'returnBook') {
        isConfirmed = window.confirm('Подтвердить возвращение брони?');
    } else if (method === 'cancelReceptionFullProgram') {
        isConfirmed = window.confirm('Если удалили запись из программы то жмите ОК!');
    }
    const token = localStorage.getItem('accessToken');
    if (isConfirmed) {
      try {
          const response = await fetch(`${apiUrl}/api/sys/set-statusbook?booking_id=${bookginId}&method=${method}`, {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/json',
                  "Authorization": `Bearer ${token}`
              },
          });
          const data = await response.json();
            return data.request.message
      } catch (error) {
          console.error("Ошибка:", error);
      }
    }
  }


export function formatDateTime(dateString) {
    const date = new Date(dateString);

    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Месяцы от 0 до 11
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
}