import React, { useEffect, useState } from 'react';
import { apiUrl } from '../../../config'
import { fetchData } from '../../../utils/api';
import { useLanguage } from '../../../LanguageContext';


const BookingNew = ({ onClose }) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const { language } = useLanguage();
    const [guests, setGuests] = useState([{ full_name: '', passport_serial_number: '' }]);
    
    const [messageNewData, setMessageNewData] = useState('')
    const [rooms, setRooms] = useState([])
    const [selectedPrice, setSelectedPrice] = useState('')
    const [newBooking, setNewBooking] = useState({
        'phone_number': '',
        'email': '',
        'available_from': '',
        'available_to': '',
        'room_id': '',
        'type': 0
    });
    async function getRooms() {
        try {
            const token = localStorage.getItem('accessToken');
            const rooms = await fetchData(`${apiUrl}/api/sys/get-rooms-by-season`, 'GET', token)            
            if (rooms.request.status === 0) {
                setRooms(rooms.request.data)
            }
        } catch (error) {
            console.error("Не удалось получить данные о номерах:", error);
        }
    }

    useEffect(() => {
        getRooms()
    }, [])
    useEffect(() => {
        // Проверяем, есть ли данные rooms и установлен ли room_id
        if (rooms && rooms.length > 0 && newBooking.room_id) {
            const selectedRoom = rooms.find((room) => room.id === parseInt(newBooking.room_id));
            setSelectedPrice(selectedRoom ? selectedRoom.price : '');
        }
    }, [rooms, newBooking.room_id])

    const sendData = async () => {
        const requestData = {...newBooking, guests}
        setIsDisabled(true)
        setMessageNewData('Подождите...')
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(`${apiUrl}/api/sys/new-booking`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestData)
            });
            const data = await response.json();
            if (response.ok) {
                setMessageNewData(data.request.message)
            } else {
                setMessageNewData(data.request.message)
                console.error("Ошибка загрузки данных:", response.statusText);
            }
        } catch (error) {
            console.error("Ошибка:", error);
        }
        setTimeout(() => setIsDisabled(false), 3000);
    }


    // Обработчик изменений для гостя
    const handleGuestChange = (index, field, value) => {
        const updatedGuests = [...guests];
        updatedGuests[index][field] = value;
        setGuests(updatedGuests);
    };

    // Добавление гостя
    const addGuest = () => {
        setGuests([...guests, { full_name: '', passport_serial_number: '' }]);
    };

    // Удаление гостя
    const removeGuest = (index) => {
        if (guests.length > 1) {
            setGuests(guests.filter((_, i) => i !== index));
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewBooking((prevBooking) => ({ ...prevBooking, [name]: value }));

        if (name === 'room_id') {
            // Находим цену для выбранной комнаты и обновляем состояние
            const selectedRoom = rooms.find((room) => room.id === parseInt(value));
            setSelectedPrice(selectedRoom ? selectedRoom.price : ''); // Просто для отрисовки на странице
        }
    };
    
    return (
        <div className='system-background-hide'>
            <section className="system-container system-container-form">
                <div className='system-container__inner'>
                    <header className="system-header">Новая бронь</header>
                    <form className="system-form">
                        <div className='booking-form__buttons'>
                            {/* Кнопка добавления гостя */}
                            <button type="button" className='button-booking button-booking-small' onClick={addGuest}>
                                Добавить гостя
                            </button>
                        </div>
                        <div className='booking-form__guests'>
                            {guests.map((guest, index) => (
                            <div key={index} className='booking-form__guests___guest'>
                                <div className="system-input-box">
                                    <label>Полное имя #{(1+index)}</label>
                                    <input
                                        required
                                        placeholder="Имя Фамилия"
                                        value={guest.full_name}
                                        type="text"
                                        id={`full_name_${index}`}
                                        name={`full_name_${index}`}
                                        onChange={(e) => handleGuestChange(index, 'full_name', e.target.value)}
                                        />
                                </div>
                                <div className="system-input-box">
                                    <label>Серийный номер паспорта</label>
                                    <input
                                        required
                                        placeholder="Серийный номер паспорта"
                                        value={guest.passport_serial_number}
                                        type="text"
                                        id={`passport_serial_number_${index}`}
                                        name={`passport_serial_number_${index}`}
                                        onChange={(e) => handleGuestChange(index, 'passport_serial_number', e.target.value)}
                                        />
                                </div>
                                {guests.length > 1 && (
                                    <button type="button" className='button-booking button-booking-small' onClick={() => removeGuest(index)}>
                                        Удалить гостя
                                    </button>
                                )}
                            </div>
                            ))}
                        </div>
                        <div className="system-input-box">
                            <label>Телефон</label>
                            <input required placeholder="Введите номер тел." value={newBooking.phone_number} type="telephone" name="phone_number" onChange={handleInputChange} />
                        </div>
                        <div className="system-input-box">
                            <label>Почта</label>
                            <input placeholder="Введите почту" value={newBooking.email} type="telephone" name="email" onChange={handleInputChange} />
                        </div>
                        <div className="system-column">
                            <div className="system-input-box">
                                <label>Начало брони</label>
                                <input required value={newBooking.available_from} placeholder="Заезд" type="date" name='available_from' onChange={handleInputChange} />
                            </div>
                            <div className="system-input-box">
                                <label>Окончание брони</label>
                                <input required value={newBooking.available_to} placeholder="Выезд" type="date" name='available_to' onChange={handleInputChange} />
                            </div>
                        </div>
                        <div className="system-input-box system-address">
                            <label>Тип бронирования</label>
                            <div className="system-column">
                                <div className="system-select-box">
                                    <select required value={newBooking.type} onChange={handleInputChange} name='type'>
                                        <option value="0">Не выбрано</option>
                                        <option value="2">Ссылкой</option>
                                        <option value="1">Бланком</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="system-input-box system-address">
                            <label>Тип номера</label>
                            <div className="system-column">
                                <div className="system-select-box">
                                    <select required value={newBooking.room_id || ''} onChange={handleInputChange} name='room_id'>
                                        <option value="0">Не выбрано</option>
                                        {rooms && rooms.length > 0 ? (
                                            rooms.map((room) => (
                                                <option key={room.id} value={room.id}>{room.translations.room_type[language]}</option>
                                            ))
                                        ) : (
                                            <option value="">Нет номеров</option>
                                        )}

                                    </select>
                                </div>
                                <input placeholder={selectedPrice || "Цена не доступна"} disabled type="text" />
                            </div>
                        </div>
                        <div className='flex-btns'>
                            <button type='button' className="system-booking-table-btn system-booking-table-btn-cancel" onClick={onClose}>Закрыть</button>
                            <button type='button' className="system-booking-table-btn system-booking-table-btn-view" disabled={isDisabled} onClick={() => sendData()}>Сохранить</button>
                        </div>
                        {messageNewData.length ? (
                            <span className='error-message'>{messageNewData}</span>
                        ) : (null)}
                    </form>
                </div>
            </section>
        </div>
    );
}

export default BookingNew;