import React, { useEffect } from 'react';
import '../styles/css/index.css';

import HeaderComponent from '../components/HeaderComponent';
import FooterComponent from '../components/FooterComponent';

import SliderComponent from '../components/HomePage/SliderComponent';
import SliderRoomsComponent from '../components/HomePage/SliderRoomsComponent';
import { useLocation } from 'react-router-dom';
import VideosComponent from '../components/VideosComponent';
import { useLanguage } from '../LanguageContext';


function Home() {
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const message = queryParams.get("message");

    useLanguage();

      useEffect(() => {
        if (message) {
          // Выводим его в alert
          alert(message);
        }
      }, []);

    return (
        <React.Fragment>
            <HeaderComponent />
            <SliderComponent />
            <SliderRoomsComponent />
            <VideosComponent />
            <FooterComponent />
        </React.Fragment>
    );
}

export default Home;
