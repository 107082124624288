import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchData } from '../utils/api';
import { formatDateTime } from '../utils/dynamic';
import { apiUrl } from '../config';
import '../styles/scss/PaymentByUrl.scss';  // Подключаем стили

function PaymentByUrl() {
    const { book_number, code } = useParams(); // Получаем параметр :book_number и :code из URL
    const [data, setData] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const fetchB = async () => {
            const result = await fetchData(`${apiUrl}/api/get-booking-code/${book_number}/${code}`);
            if (result.request.status === 0) {
                setData(result.request.data);
            } else {
                setMessage(result.request.message);
            }
        };
        fetchB();
    }, [book_number, code]);
    const handlePayment = async (book_number, code) => {
        setIsDisabled(true)
        const result = await fetchData(`${apiUrl}/api/payment/${book_number}/${code}`)
        if (result.request.status === 0) {
            // Навигация на страницу оплаты
            window.location.href = result.request.data.formUrl
        } else {
            setMessage(result.request.message)
            // Включаем кнопку снова через 3 секунды
            setTimeout(() => setIsDisabled(false), 3000);
        }
    };

    return (
        <div className="payment-container">
            <div className="payment-card">
                {data ? (
                    <>
                        <h1>Данные о бронировании для оплаты</h1>
                        <div className="booking-info">
                            <p><strong>Номер бронирования:</strong> {data.booking.book_number}</p>
                            {data.guests && data.guests.map((guest, index) => (
                                <React.Fragment key={index}>
                                <h3>Гости:</h3>
                                <p><strong>№{1+index}: Имя/Фамилия</strong> {guest['full_name']}</p>
                                <p><strong>Серия и номер паспорта</strong> {guest['passport_serial_number']}</p>
                                </React.Fragment>
                            ))}
                            <p><strong>Дата заезда:</strong> {formatDateTime(data.booking.available_from)}</p>
                            <p><strong>Дата выезда:</strong> {formatDateTime(data.booking.available_to)}</p>
                            <p><strong>Тип номера:</strong> {data.translations.room_type}</p>
                            <p><strong>Сумма:</strong> {data.booking.amount} TMT</p>
                        </div>
                        {data.booking.status === 1 && data.booking.type === 2 ? (
                        <button className="payment-button" disabled={isDisabled} onClick={() => handlePayment(book_number, code)}>
                            Перейти к оплате
                        </button>
                        ) : (
                            <h4>Бронь уже обработана</h4>
                        )}
                    </>
                ) : (
                    <p>{message || 'Загрузка данных о бронировании...'}</p>
                )}
            </div>
        </div>
    );
}

export default PaymentByUrl;