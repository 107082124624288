import React, { useEffect, useState } from 'react';
import useAuthRedirect from '../../utils/modules/useAuthRedirect';
import { fetchData } from '../../utils/api';
import { apiUrl } from '../../config';
import HeaderComponent from '../HeaderComponent';
import FooterComponent from '../FooterComponent';
import ClientBookCancelComponent from './ClientBookCancelComponent'
import { useLanguage } from '../../LanguageContext';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const ClientBooksComponent = () => {  
  const { language } = useLanguage();
  const [bookings, setBookings] = useState([]);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const message = queryParams.get("message");
  // Проверка авторизации при загрузке компонента
  const fetchGAB = async () => {
    try {
      const token = localStorage.getItem('accessToken');
      // Загружаем брони клиента (замени URL на свой эндпоинт)
      const response = await fetchData(`${apiUrl}/api/client/bookings`, "GET", token)
      if (response.request.status === 0) {
        const processedData = response.request.data.booking
          .map((booking) => {
            const room = response.request.data.rooms.find((room) => room.id === booking.room_id);
            const customer = response.request.data.customers.find((customer) => customer.id === booking.customer_id);
            return {
              ...booking,
              room_type: room ? room.translations.room_type[language] : 'Неизвестно', // Тип номера
              price: room ? room.price : 'Неизвестно', // Цена за первые сутки номера
              book_price: response.request.data.book_price ? response.request.data.book_price : 'Неизвестно', // Цена за бронь
              full_name: customer ? customer.full_name : 'Неизвестно', // Полное 
              email: customer ? customer.email : 'Неизвестно', // Почта
              phone_number: customer ? customer.phone_number : 'Неизвестно', // Номер тел
            };
          })
          .sort((a, b) => a.status - b.status);
        setBookings(processedData)
      }
    } catch (error) {
      console.error('Ошибка: ', error)
    }
  }

  const getStatusMessage = (status) => {
    const messages = {
      'ru': {
        0: "Успешно",
        1: "Не оплачено.",
        2: "Ожидается",
        3: "Произведен возврат средств",
      },
      'tm': {
        0: "Başaraly",
        1: "Tölegli däl",
        2: "Gözlenilýär",
        3: "Puly yzyna gaýtarmak geçirildi",
      },
      'en': {
        0: "Successful",
        1: "Not paid.",
        2: "Pending",
        3: "Refund processed",
      },
    };
  
    return messages[language][status] || '';
  };

  const getPaymentType = (type) => {
    const messages = {
      'ru': {
        0: 'Онлайн',
        1: 'Ссылкой',
        2: 'Через банк',
      },
      'tm': {
        0: 'Onlaýn',
        1: 'Ссылкой',
        2: 'Bank arkaly töleg',
      },
      'en': {
        0: 'Online',
        1: 'Payment by link',
        2: 'Payment via bank',
      },
    }

    return messages[language][type] || '';

  }

  // Состояние для языка
  const langs = {
    'ru': {
      'returnpay_title': "Возврат средств",
      'returnpay_link': "Загрузить бланк заявления",
      'returnpolicy_link': "Загрузить правила по возврату средств",

      'books_title': "Ваши бронирования",
      'room_type': "Тип номера",
      'book_number': "Номер брони",
      'guest': "Гость",
      'full_name': "Имя фамилия",
      'passport_serial_number': "Серия и номер паспорта",
      'check_in': "Заезд",
      'check_out': "Выезд",
      'status': "Статус оплаты",
      'amount': "Общая сумма",
      'message': "У вас пока нет бронирований.",
      'payment_type': "Тип оплаты.",
      'prebuttonemail': "После того как будет заполнено заявление отправьте его нам на почту. Отменить бронь можно за 72 часа до начала брони."
    },
    'tm': {
      'returnpay_title': "Yzyna gaýtaryp bermek",
      'returnpay_link': "Arzany ýükläp almak",
      'returnpolicy_link': "Pul serişdelerini yzyna gaýtarmak kadalaryny ýükläp alyň.",

      'books_title': "Bronlaryňyz",
      'room_type': "Otag görnüşi",
      'book_number': "Bronlama belgisi",
      'guest': "Myhman",
      'full_name': "Ady we familiýasy",
      'passport_serial_number': "Pasportyň seriýasy we belgisi",
      'check_in': "Geliş wagty",
      'check_out': "Çykyş wagty",
      'status': "Töleg ýagdaýy",
      'amount': "Jemi mukdar",
      'message': "Sizde henizem bronlama ýok.",
      'payment_type': "Töleg görnüşi.",
      'prebuttonemail': "Arzany doly ýagdaýda dolduranyňyzdan soňra elektron poçtamyza ibermegiňizi haýyş edýäris. Sargyt etmegi diňe giriş günüňizden 72 sagat öňünden  yzyna gaýtarmak mümkin."
    },
    'en': {
      'returnpay_title': "Refund",
      'returnpay_link': "Upload application",
      'returnpolicy_link': "Download the refund policy",

      'books_title': "Your bookings",
      'room_type': "Room type",
      'book_number': "Booking number",
      'guest': "Guest",
      'full_name': "Full name",
      'passport_serial_number': "Passport series and number",
      'check_in': "Check-in",
      'check_out': "Check-out",
      'status': "Payment status",
      'amount': "Total amount",
      'message': "You don't have any bookings yet.",
      'payment_type': "Payment type.",
      'prebuttonemail': "After filling out the application, send it to me. You can cancel your reservation up to 72 hours before the start of your reservation."
    }
  };
  useEffect(() => {
    if (message) {
      // Выводим его в alert
      alert(message);
    }
    fetchGAB()
  }, []);

  const handleDownload = async (f) => {
    // Путь к шаблону заявления на сервере или в public директории
      const token = localStorage.getItem('accessToken');
      const response = await axios.get(`${apiUrl}/api/client/${f}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'blob',  // Указываем тип ответа как blob (для бинарных данных)
      });
        
      // Создаём ссылку на Blob и инициируем скачивание
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      let name;
      if (f === 'cancellation-terms') {
        name = 'returnpolicy.pdf'
      } else if (f === 'get-application') {
        name = 'returnpay.docx'
      }
      link.setAttribute('download', name); // Имя файла
      document.body.appendChild(link);
      link.click();

      // Очистка ресурсов
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
  };
  return (
    <React.Fragment>
      <HeaderComponent />
      <div className="books-container">
        <h1 className="books-title">{langs[language].returnpay_title}</h1>
        <ul className="books-list">
            <li className="booking-item">
              <h3><button className='button-zj pulse-button button-booking-small' onClick={() => handleDownload('cancellation-terms')}>{langs[language].returnpolicy_link}</button></h3>
              <h3><button className='button-zj button-booking-small' onClick={() => handleDownload('get-application')}>{langs[language].returnpay_link}</button></h3>
              <p>{langs[language].prebuttonemail} <br /><a href='mailto:berkarar.travel.avaza@gmail.com'>berkarar.travel.avaza@gmail.com</a></p>
            </li>
        </ul>
        <h1 className="books-title">{langs[language].books_title}</h1>
        {bookings.length > 0 ? (
          <ul className="books-list">
            {bookings.map((booking) => (
              <li key={booking.id} className="booking-item">
                <h3>{langs[language].room_type}: {booking.room_type}</h3>
                {booking.guests.map((guest, index) => (
                  <div key={index} className='booking-item-guest'>
                  <p>{langs[language].guest} №{++index}</p>
                  <p>{langs[language].full_name}: {guest.full_name}</p>
                  <p>{langs[language].passport_serial_number}: {guest.passport_serial_number}</p>
                  </div>
                ))}
                <p>{langs[language].book_number}: {booking.book_number}</p>
                <p>{langs[language].full_name}: {booking.full_name}</p>
                <p>{langs[language].check_in}: {booking.available_from}</p>
                <p>{langs[language].check_out}: {booking.available_to}</p>
                <p>{langs[language].amount}: {booking.amount} TMT</p>
                <p>{langs[language].payment_type}: {getPaymentType(booking.type)}</p>
                <p className='red-text'>{langs[language].status}: {getStatusMessage(booking.status)}</p>
              </li>
            ))}
          </ul>
        ) : (
          <p>{langs[language].message}</p>
        )}
      </div>
      <FooterComponent />
    </React.Fragment>
  )
}

export default ClientBooksComponent;