import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLanguage } from '../LanguageContext';
import '../styles/scss/BookingButton.scss'
import { minDaysBooking, maxDaysBooking, minDateBooking, maxDateBooking, maxDaysBookingPeriod } from '../utils/vars';
import { fetchData } from '../utils/api';
import { apiUrl } from '../config';
import Protected from './system/Protected';

const BookingButton = () => {
    const [mode, setMode] = useState('dates'); // Режим: "days" или "dates"
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [checkInDate, setCheckInDate] = useState("");
    const [checkOutDate, setCheckOutDate] = useState("");
    const [checkOutMin, setCheckOutMin] = useState("");
    const [checkOutMax, setCheckOutMax] = useState("");
    const location = useLocation();
    const navigate = useNavigate();
    const { language } = useLanguage();

    useEffect(() => {
      const params = new URLSearchParams(location.search);
      const message = params.get('message');
  
      if (message) {
        alert(`Bank: ${message}`);
        navigate('/', { replace: true });
      }
    }, [location, navigate]);

    // Вычисление диапазонов для "days" режима
    const today = new Date();
    const minDateByDays = new Date(today.getTime() + minDaysBooking * 24 * 60 * 60 * 1000);
    const maxDateByDays = new Date(today.getTime() + maxDaysBooking * 24 * 60 * 60 * 1000);

    const calculateCheckOutRange = (checkIn) => {
        const selectedCheckIn = new Date(checkIn);
        const minCheckOut = new Date(selectedCheckIn.getTime() + 1 * 24 * 60 * 60 * 1000); // Минимум 1 день после заезда
        const maxCheckOut = mode === 'days'
        ? new Date(Math.min(selectedCheckIn.getTime() + maxDaysBooking * 24 * 60 * 60 * 1000, maxDateByDays.getTime()))
        : new Date(maxDateBooking);

        return {
          minCheckOut: minCheckOut.toISOString().split("T")[0],
          maxCheckOut: maxCheckOut.toISOString().split("T")[0],
        };
    };
    const handleChange = (event) => {
        const { name, value } = event.target;

        if (name === 'cin') {
          setCheckInDate(value);
          const { minCheckOut, maxCheckOut } = calculateCheckOutRange(value);
          setCheckOutMin(minCheckOut);
          setCheckOutMax(maxCheckOut);

          if (checkOutDate && (checkOutDate < minCheckOut || checkOutDate > maxCheckOut)) {
              setCheckOutDate('');
          }
        } else if (name === 'cout') {
          const selectedCheckOut = new Date(value);
          const selectedCheckIn = new Date(checkInDate);
          const sevenDaysLater = new Date(selectedCheckIn.getTime() + maxDaysBookingPeriod * 24 * 60 * 60 * 1000);
          
          // Если выбранная дата выезда превышает 7 дней после даты заезда, установить ее на 7 дней после даты заезда
          if (selectedCheckOut > sevenDaysLater) {
            if (language === 'ru') {
              alert(`Максимум ${maxDaysBookingPeriod} дней`)
            } else if (language === 'tm') {
              alert(`Iň ýokary möhlet ${maxDaysBookingPeriod} gün`)
            } else if (language === 'en') {
              alert(`Maximum period ${maxDaysBookingPeriod} days`)
            }
              // setCheckOutDate(sevenDaysLater.toISOString().split("T")[0]);
          } else {
              setCheckOutDate(value);
          }
        }
    };

  
  
    const handleSearch = async (event) => {
      
        event.preventDefault();
        if (checkInDate && checkOutDate) {
            const token = localStorage.getItem('accessToken');
            console.log()
            if (!token) {
              return;
            }
            const result = await fetchData(`${apiUrl}/api/${language}/search-rooms`, "POST", token, {body: {"checkIn": checkInDate, "checkOut": checkOutDate}})
            if (result?.request?.status === 0) {
              result.request.data.checkIn = checkInDate
              result.request.data.checkOut = checkOutDate
            }
            // Если уже на странице результатов
            if (location.pathname === "/book-rooms") {
                // Обновляем данные через состояние
                navigate("/book-rooms", { replace: true, state: { searchResults: result } });
            } else {
                // Переход на страницу результатов
                navigate("/book-rooms", { state: { searchResults: result } });
            }
        }
    };

  
    const langs = {
      ru: {
        check_dates: "Выберите даты",
        check_in: "Бронь с",
        check_out: "Бронь до",
        button_book: "Найти свободный номер",
        // button_book: "Забронировать",
        registration: "Вход",
      },
      tm: {
        check_dates: "Senesi saýlaň",
        check_in: "Giriş sene",
        check_out: "Çykyş sene",
        button_book: "Açyk otag tapmak",
        registration: "Giriş",
      },
      en: {
        check_dates: "Select dates",
        check_in: "Check-in from",
        check_out: "Check-out until",
        button_book: "Find an unoccupied room",
        registration: "Log in",
      },
    };

  const role = localStorage.getItem('asd');
  const token = localStorage.getItem('accessToken');
  const toggleForm = () => {
    if (token && role === 'client') {
      setIsFormVisible(!isFormVisible) // Если всё ок, открываем форму
    } else {
      window.location.href = '/authorization'; // Если не авторизован, перенаправляем
    }
  };
  return (
    <div className="booking-container">
      {role === 'client' && token ? (
          <button className="booking-button" onClick={toggleForm}>
            {langs[language].button_book}
          </button>
      ) : (
        <a className="booking-button" href='/authorization'>{langs[language].registration}</a>
      )}
      {isFormVisible && (
        <Protected element={
          <div className="booking-form">
            <h3>{langs[language].check_dates}</h3>
            <form onSubmit={handleSearch}>
              <label htmlFor="cin">
                {langs[language].check_in}:
                <input
                  type="date"
                  id="cin"
                  name="cin"
                  value={checkInDate}
                  min={mode === 'days' 
                    ? minDateByDays.toISOString().split("T")[0] 
                    : new Date(minDateBooking).toISOString().split("T")[0]}
                  max={mode === 'days'
                    ? maxDateByDays.toISOString().split("T")[0] 
                    : new Date(maxDateBooking).toISOString().split("T")[0]}
                  onChange={handleChange}
                  required
                />
              </label>
              <label htmlFor="cout">
                {langs[language].check_out}:
                <input
                  type="date"
                  id="cout"
                  name="cout"
                  value={checkOutDate}
                  min={checkOutMin}
                  max={checkOutMax}
                  onChange={handleChange}
                  required
                />
              </label>
              <button type="submit">{langs[language].button_book}</button>
            </form>
          </div>
        } requiredRoles={['client']} url='/authorization'/>
      )}
    </div>
  );
};

export default BookingButton;
