import React from 'react';

function Header({ title }) {

  // const userRole = localStorage.getItem('asd'); // Получаем роль из localStorage
  return (
    <header className='system-header'>
      <h1>{(title) ? title : 'Дашборд'}</h1>
      {/* <div className="user-info">
        <p>Пользователь: </p>
        <a href="#">Выход</a>
      </div> */}
    </header>
  );
}

export default Header;
