import React, { useEffect, useState } from "react";
import "../styles/scss/VideosComponent.scss";
import { apiUrl } from "../config";
import axios from "axios";

const VideosComponent = () => {
    const [ videos, setVideos ] = useState([])

    useEffect(() => {
        // Запрос к API для получения списка видео
        axios
          .get(`${apiUrl}/api/client/get-main-videos`)
          .then((response) => {
            setVideos(response.data);
          })
          .catch((error) => {
            console.error("Ошибка при получении видео:", error);
          });
    }, []);

    return (
      <>
      {videos && (
        <div className="video-grid container">
          {videos.map((video, index) => (
            <div className="video-container" key={index}>
              <video controls controlsList="nodownload">
                <source src={`${apiUrl}${video.url}`} type="video/mp4" />
                Ваш браузер не поддерживает видео тег. Пожалуйста обновите браузер или попробуйте другой.
              </video>
            </div>
          ))}
        </div>
      )}
      </>
    )
};

export default VideosComponent;