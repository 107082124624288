import React, { useEffect, useState } from 'react';
import '../../styles/css/index.css'
import Card from "./Card";
import { apiUrl } from '../../config';
import { fetchData } from '../../utils/api';
import HeaderComponent from '../../components/HeaderComponent';
import FooterComponent from '../../components/FooterComponent';
import ReviewsFormComponent from '../../components/Reviews/ReviewsFormComponent';
import Protected from '../../components/system/Protected';

function Reviews() {
  const language = localStorage.getItem('language') || 'tm';
  const [reviews, setReviews] = useState([]);
  const [customerReviews, setCustomerReviews] = useState(0);

  const getReviews = async () => {
    const token = localStorage.getItem('accessToken');
    let asd = localStorage.getItem('asd');

    switch (asd) {
      case 'client':
        try {
          const result = await fetchData(`${apiUrl}/api/client/reviews/a`, 'GET', token) 
          if (result.request.status === 0) {
            setReviews(result.request.data.reviews)
            setCustomerReviews(result.request.data.customer_reviews_count)
          }
        } catch (error) {}
        break;
      default:
        try {
          const result = await fetchData(`${apiUrl}/api/client/reviews`) 
          if (result.request.status === 0) {
            setReviews(result.request.data)
          }
        } catch (error) {}
        break;
    }
  }
  useEffect(() => {
    getReviews()
  }, [])

  const langs = {
    'ru': {
      'title': "Отзывы",
    },
    'tm': {
      'title': "Teswirler",
    },
    'en': {
      'title': "Reviews",
    }
  };
  const asd = localStorage.getItem('asd');

    return (
        <React.Fragment>
          <HeaderComponent />
          <h2 className="head-title">{langs[language].title}</h2>
            {asd === 'client' && customerReviews < 2 &&
              <ReviewsFormComponent />
            }
          <section className='container'>
            <div className='reviews-cards'>
              {reviews.map((review, index) => (
                <Card key={review.id} review={review} />
              ))}
            </div>
          </section>
          <FooterComponent />
        </React.Fragment>
    );
}

export default Reviews;
