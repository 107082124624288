import React, { useState } from 'react';

function GalleryComponent() {
  const images = [
    { id: 1, image: '/media/img/gallery/gallery-01.jpg', title: 'Услуга 1', minidescription: 'Описание услуги 1' },
    { id: 2, image: '/media/img/gallery/gallery-02.jpg', title: 'Услуга 2', minidescription: 'Описание услуги 2' },
    { id: 3, image: '/media/img/gallery/gallery-03.jpg', title: 'Услуга 3', minidescription: 'Описание услуги 3' },
    { id: 4, image: '/media/img/gallery/gallery-04.jpg', title: 'Услуга 4', minidescription: 'Описание услуги 4' },
    { id: 5, image: '/media/img/gallery/gallery-05.jpg', title: 'Услуга 5', minidescription: 'Описание услуги 5' },
    { id: 6, image: '/media/img/gallery/gallery-06.jpg', title: 'Услуга 6', minidescription: 'Описание услуги 6' },
    { id: 7, image: '/media/img/gallery/gallery-07.jpg', title: 'Услуга 7', minidescription: 'Описание услуги 7' },
    { id: 8, image: '/media/img/gallery/gallery-08.jpg', title: 'Услуга 8', minidescription: 'Описание услуги 8' },
    { id: 9, image: '/media/img/gallery/gallery-09.jpg', title: 'Услуга 9', minidescription: 'Описание услуги 9' },
    { id: 10, image: '/media/img/gallery/gallery-10.jpg', title: 'Услуга 10', minidescription: 'Описание услуги 10' },
    { id: 11, image: '/media/img/gallery/gallery-11.jpg', title: 'Услуга 11', minidescription: 'Описание услуги 11' },
    { id: 12, image: '/media/img/gallery/gallery-12.jpg', title: 'Услуга 12', minidescription: 'Описание услуги 12' },
    { id: 13, image: '/media/img/gallery/gallery-13.jpg', title: 'Услуга 13', minidescription: 'Описание услуги 13' },
    { id: 14, image: '/media/img/gallery/gallery-14.jpg', title: 'Услуга 14', minidescription: 'Описание услуги 14' },
    { id: 15, image: '/media/img/gallery/gallery-15.jpg', title: 'Услуга 15', minidescription: 'Описание услуги 15' },
    { id: 16, image: '/media/img/gallery/gallery-16.jpg', title: 'Услуга 16', minidescription: 'Описание услуги 16' },
    { id: 17, image: '/media/img/gallery/gallery-17.jpg', title: 'Услуга 17', minidescription: 'Описание услуги 17' },
    { id: 18, image: '/media/img/gallery/gallery-18.jpg', title: 'Услуга 18', minidescription: 'Описание услуги 18' },
    { id: 19, image: '/media/img/gallery/gallery-19.jpg', title: 'Услуга 19', minidescription: 'Описание услуги 19' },
    { id: 20, image: '/media/img/gallery/gallery-20.jpg', title: 'Услуга 20', minidescription: 'Описание услуги 20' },
    { id: 21, image: '/media/img/gallery/gallery-21.jpg', title: 'Услуга 21', minidescription: 'Описание услуги 21' },
    { id: 22, image: '/media/img/gallery/gallery-22.jpg', title: 'Услуга 22', minidescription: 'Описание услуги 22' },
    { id: 23, image: '/media/img/gallery/gallery-23.jpg', title: 'Услуга 23', minidescription: 'Описание услуги 23' },
    { id: 24, image: '/media/img/gallery/gallery-24.jpg', title: 'Услуга 24', minidescription: 'Описание услуги 24' },
    { id: 25, image: '/media/img/gallery/gallery-25.jpg', title: 'Услуга 25', minidescription: 'Описание услуги 25' },
    { id: 26, image: '/media/img/gallery/gallery-26.jpg', title: 'Услуга 26', minidescription: 'Описание услуги 26' },
    { id: 27, image: '/media/img/gallery/gallery-27.jpg', title: 'Услуга 27', minidescription: 'Описание услуги 27' },
    { id: 28, image: '/media/img/gallery/gallery-28.jpg', title: 'Услуга 28', minidescription: 'Описание услуги 28' },
    { id: 29, image: '/media/img/gallery/gallery-29.jpg', title: 'Услуга 29', minidescription: 'Описание услуги 29' },
    { id: 30, image: '/media/img/gallery/gallery-30.jpg', title: 'Услуга 30', minidescription: 'Описание услуги 30' },
    { id: 31, image: '/media/img/gallery/gallery-31.jpg', title: 'Услуга 31', minidescription: 'Описание услуги 31' },
    { id: 32, image: '/media/img/gallery/gallery-32.jpg', title: 'Услуга 32', minidescription: 'Описание услуги 32' },
    { id: 33, image: '/media/img/gallery/gallery-33.jpg', title: 'Услуга 33', minidescription: 'Описание услуги 33' },
    { id: 34, image: '/media/img/gallery/gallery-34.jpg', title: 'Услуга 34', minidescription: 'Описание услуги 34' },
    { id: 35, image: '/media/img/gallery/gallery-35.jpg', title: 'Услуга 35', minidescription: 'Описание услуги 35' },
    { id: 36, image: '/media/img/gallery/gallery-36.jpg', title: 'Услуга 36', minidescription: 'Описание услуги 36' },
    { id: 37, image: '/media/img/gallery/gallery-37.jpg', title: 'Услуга 37', minidescription: 'Описание услуги 37' },
    { id: 38, image: '/media/img/gallery/gallery-38.jpg', title: 'Услуга 38', minidescription: 'Описание услуги 38' },
    { id: 39, image: '/media/img/gallery/gallery-39.jpg', title: 'Услуга 39', minidescription: 'Описание услуги 39' },
    { id: 40, image: '/media/img/gallery/gallery-40.jpg', title: 'Услуга 40', minidescription: 'Описание услуги 40' },
    { id: 41, image: '/media/img/gallery/gallery-41.jpg', title: 'Услуга 41', minidescription: 'Описание услуги 41' },
    { id: 42, image: '/media/img/gallery/gallery-42.jpg', title: 'Услуга 42', minidescription: 'Описание услуги 42' },
    { id: 43, image: '/media/img/gallery/gallery-43.jpg', title: 'Услуга 43', minidescription: 'Описание услуги 43' },
    { id: 44, image: '/media/img/gallery/gallery-44.jpg', title: 'Услуга 44', minidescription: 'Описание услуги 44' },
    { id: 45, image: '/media/img/gallery/gallery-45.jpg', title: 'Услуга 45', minidescription: 'Описание услуги 45' },
    { id: 46, image: '/media/img/gallery/gallery-46.jpg', title: 'Услуга 46', minidescription: 'Описание услуги 46' },
    { id: 47, image: '/media/img/gallery/gallery-47.jpg', title: 'Услуга 47', minidescription: 'Описание услуги 47' },
    { id: 48, image: '/media/img/gallery/gallery-48.jpg', title: 'Услуга 48', minidescription: 'Описание услуги 48' },
    { id: 49, image: '/media/img/gallery/gallery-49.jpg', title: 'Услуга 49', minidescription: 'Описание услуги 49' },
    { id: 50, image: '/media/img/gallery/gallery-50.jpg', title: 'Услуга 50', minidescription: 'Описание услуги 50' },
    { id: 51, image: '/media/img/gallery/gallery-51.jpg', title: 'Услуга 51', minidescription: 'Описание услуги 51' },
    { id: 52, image: '/media/img/gallery/gallery-52.jpg', title: 'Услуга 52', minidescription: 'Описание услуги 52' },
    { id: 53, image: '/media/img/gallery/gallery-53.jpg', title: 'Услуга 53', minidescription: 'Описание услуги 53' },
    { id: 54, image: '/media/img/gallery/gallery-54.jpg', title: 'Услуга 54', minidescription: 'Описание услуги 54' },
    { id: 55, image: '/media/img/gallery/gallery-55.jpg', title: 'Услуга 55', minidescription: 'Описание услуги 55' },
    { id: 56, image: '/media/img/gallery/gallery-56.jpg', title: 'Услуга 56', minidescription: 'Описание услуги 56' },
    { id: 57, image: '/media/img/gallery/gallery-57.jpg', title: 'Услуга 57', minidescription: 'Описание услуги 57' },
    { id: 58, image: '/media/img/gallery/gallery-58.jpg', title: 'Услуга 58', minidescription: 'Описание услуги 58' },
    { id: 59, image: '/media/img/gallery/gallery-59.jpg', title: 'Услуга 59', minidescription: 'Описание услуги 59' },
    { id: 60, image: '/media/img/gallery/gallery-60.jpg', title: 'Услуга 60', minidescription: 'Описание услуги 60' },
    { id: 61, image: '/media/img/gallery/gallery-61.jpg', title: 'Услуга 61', minidescription: 'Описание услуги 61' },
    { id: 63, image: '/media/img/gallery/gallery-63.jpg', title: 'Услуга 63', minidescription: 'Описание услуги 63' },
    { id: 64, image: '/media/img/gallery/gallery-64.jpg', title: 'Услуга 64', minidescription: 'Описание услуги 64' },
    { id: 65, image: '/media/img/gallery/gallery-65.jpg', title: 'Услуга 65', minidescription: 'Описание услуги 65' },
  ];

    // Состояние для модального окна и выбранного изображения
    const [isOpen, setIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    // Открытие модального окна с выбранной картинкой
    const openModal = (image) => {
        setSelectedImage(image);
        setIsOpen(true);
    };

    // Закрытие модального окна
    const closeModal = () => {
        setIsOpen(false);
        setSelectedImage(null);
    };

    const savedLanguage = localStorage.getItem('language') || 'tm';
    // Состояние для языка
    const langs = {
      'ru': {
        'title': "Галерея",
      },
      'tm': {
        'title': "Galereýa",
      },
      'en': {
        'title': "Gallery",
      }
    };

    return (
        <section className="gallery container">
        <h3 className="gallery-title">{langs[savedLanguage].title}</h3>
        <div className="row">
          <ul>
            {images.map((item, index) => (
              <li key={index} onClick={() => openModal(item.image)}>
                <img src={item.image} alt={`Item ${index}`} />
              </li>
            ))}
          </ul>
        </div>
  
        {/* Модальное окно для отображения выбранной картинки на полный экран */}
        {isOpen && (
          <div className="modal" onClick={closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <img src={selectedImage} alt="Full size" />
              <button className="close-button" onClick={closeModal}>X</button>
            </div>
          </div>
        )}
      </section>
    );
}

export default GalleryComponent;
