import React, { useState } from 'react';
import axios from 'axios';
import { apiUrl } from '../../config';

const DownloadReport = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const handleDownload = async () => {
    if (!startDate || !endDate) {
      alert("Пожалуйста, выберите обе даты!");
      return;
    }
    try {
      // Отправка GET-запроса на сервер
      const response = await axios.get(`${apiUrl}/api/sys/generate-report/bookings`, {
        params: {
          type: 'day', // или 'week', 'month'
          start_date: startDate, // Пример даты
          end_date: endDate, // Пример даты
        },
        responseType: 'blob', // Важно для правильной обработки файла
      });

      // Создание ссылки для скачивания файла
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = fileURL;
      link.setAttribute('download', `report_${startDate}_${endDate}.xlsx`); // Имя файла
      document.body.appendChild(link);
      link.click();

      // Убираем ссылку после скачивания
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  return (
    <div>
      <div className='d-flex'>
        <div className='dinput-block'>
          <label htmlFor="start-date">Начальная дата:</label>
          <input
            className='dinput'
            type="date"
            id="start-date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className='dinput-block'>
          <label htmlFor="end-date">Конечная дата:</label>
          <input
            className='dinput'
            type="date"
            id="end-date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
      </div>
      <button className='system-booking-table-btn system-booking-table-btn-view' onClick={handleDownload}>Получить отчет</button>
    </div>
  );
};

export default DownloadReport;
