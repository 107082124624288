import React, { useState, useEffect } from 'react';
import { apiUrl } from '../../config';
import { fetchData } from '../../utils/api';

import styles from '../../styles/scss/system/Customers.module.scss';

const Customers = ({ setHeaderTitle }) => {
    const [customers, setCustomers] = useState(null);
    const [customersCount, setCustomersCount] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Инициализация заголовка
    useEffect(() => {
        setHeaderTitle('Клиенты');
    }, [setHeaderTitle]);

    useEffect(() => {
        // Функция для получения данных о количестве пользователей
        const fetchCustomers = async () => {
            try {
                const token = localStorage.getItem('accessToken');
                const response = await fetchData(`${apiUrl}/api/sys/get-customers`, "GET", token);
                if (response.request.status === 0) {
                    setCustomers(response.request.data.customers);
                    setCustomersCount(response.request.data.count);
                    setLoading(false)
                } else {
                    setError(response.request.message);
                }
            } catch (error) {
                setError(error.message);
            }
        };
        // Вызываем функции для получения данных
        fetchCustomers();
    }, []);

    const handleDelete = async (customerId) => {
        const isConfirmed = window.confirm('Вы уверены, что хотите удалить этого клиента?');
        if (isConfirmed) {
            const confirmationWord = prompt('Введите слово УДАЛИТЬ для подтверждения.');
            if (confirmationWord === "УДАЛИТЬ") {
                const token = localStorage.getItem('accessToken');
                const response = await fetchData(`${apiUrl}/api/sys/customers/${customerId}/remove`, "GET", token)
                if (response.request.status === 0) {
                    alert(response.request.message)
                } else {
                    alert("Ошибка удаления пользователя")
                }
            } else {
                alert("Не верно введено 'УДАЛИТЬ'")
            }
        }
        
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className={styles.customersList}>
            <h3 className={styles.customersListHeading}>Количество клиентов: {customersCount}</h3>
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th className={styles.th}>ID</th>
                        <th className={styles.th}>Имя/фамилия</th>
                        <th className={styles.th}>Email</th>
                        <th className={styles.th}>Номер телефона</th>
                        <th className={styles.th}>Зарегистрировался</th>
                        <th className={styles.th}>Действия</th>
                    </tr>
                </thead>
                <tbody>
                    {customers.map((customer, index) => (
                        <tr key={customer.id} className={index % 2 === 0 ? styles.trEven : ''}>
                            <td className={styles.td}>{customer.id}</td>
                            <td className={styles.td}>{customer.full_name || 'N/A'}</td>
                            <td className={styles.td}>{customer.email}</td>
                            <td className={styles.td}>{customer.phone_number || 'N/A'}</td>
                            <td className={styles.td}>{customer.created_at}</td>
                            <td className={styles.td}>
                                <button className={styles.buttonDelete} onClick={() => handleDelete(customer.id)}>
                                    Удалить
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Customers;