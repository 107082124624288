import React, { useEffect, useState } from 'react';
import { apiUrl } from '../../../config'
import { fetchData, formatDate } from '../../../utils/api';
import { boolElemByRole, formatDateTime, showTypeBooking } from '../../../utils/dynamic';
import { useLanguage } from '../../../LanguageContext';


const BookingEdit = ({ booking, onClose }) => {
    const { language } = useLanguage();
    const [messageEditData, setMessageEditData] = useState('')
    const [rooms, setRooms] = useState([])
    const [selectedPrice, setSelectedPrice] = useState('')
    const [guests, setGuests] = useState(booking.guests);
    
    const [editBooking, setEditBooking] = useState({
        ...booking,
        available_from: formatDate(booking.available_from),
        available_to: formatDate(booking.available_to)
    });

    async function getRooms() {
        try {
            const token = localStorage.getItem('accessToken');
            const rooms = await fetchData(`${apiUrl}/api/sys/get-rooms-by-season`, 'GET', token)            
            if (rooms.request.status === 0) {
                setRooms(rooms.request.data)
            }
        } catch (error) {
            console.error("Не удалось получить данные о номерах:", error);
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditBooking((prevBooking) => ({ ...prevBooking, [name]: value }));

        if (name === 'room_id') {
            // Находим цену для выбранной комнаты и обновляем состояние
            const selectedRoom = rooms.find((room) => room.id === parseInt(value));
            setSelectedPrice(selectedRoom ? selectedRoom.price : ''); // Просто для отрисовки на странице
        }
    };

    useEffect(() => {
        getRooms()
    }, [])
    useEffect(() => {
        // Проверяем, есть ли данные rooms и установлен ли room_id
        if (rooms && rooms.length > 0 && editBooking.room_id) {
            const selectedRoom = rooms.find((room) => room.id === parseInt(editBooking.room_id));
            setSelectedPrice(selectedRoom ? selectedRoom.price : '');
        }
    }, [rooms, editBooking.room_id])

    const sendData = async () => {
        const requestData = {...editBooking, guests}
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(`${apiUrl}/api/sys/edit-booking`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestData)
            });
            const data = await response.json();
            if (response.ok) {
                setMessageEditData(data.request.message)
            } else {
                setMessageEditData(data.request.message)
                console.error("Ошибка загрузки данных:", response.statusText);
            }
        } catch (error) {
            console.error("Ошибка:", error);
        }
    }




    // Обработчик изменений для гостя
    const handleGuestChange = (index, field, value) => {
        const updatedGuests = [...guests];
        updatedGuests[index][field] = value;
        setGuests(updatedGuests);
    };

    // Добавление гостя
    const addGuest = () => {
        setGuests([...guests, { full_name: '', passport_serial_number: '' }]);
    };

    // Удаление гостя
    const removeGuest = (index) => {
        if (guests.length > 1) {
            setGuests(guests.filter((_, i) => i !== index));
        }
    };
    return (
        <div className='system-background-hide'>
            <section className="system-container system-container-form">
                <div className='system-container__inner'>
                    <header className="system-header">Редактирование</header>
                    <form className="system-form">
                        <div className="system-input-box">
                            <label>Номер брони</label>
                            <input required placeholder="Номер брони" readOnly disabled value={editBooking.book_number} type="text" name='full_name' />
                        </div>
                        {boolElemByRole(['admin', 'booker']) ? (
                        (editBooking.status === 1 && (
                        <div className="system-input-box system-address">
                            <label>Тип бронирования</label>
                            <div className="system-column">
                                <div className="system-select-box">
                                    <select required value={editBooking.type} onChange={handleInputChange} name='type'>
                                        <option value="2">Ссылкой</option>
                                        <option value="1">Бланком</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        ))
                        ):(null)}
                        <div className="system-column">
                            <div className="system-input-box">
                                <label>Статус оплаты</label>
                                <p>
                                    {editBooking.type === 1 || editBooking.type === 2 ? (
                                        editBooking.payment_at ? (
                                            `Оплачено: ${formatDateTime(editBooking.payment_at)}`
                                        ) : (
                                            "Не оплачено."
                                        )
                                    ) : (
                                        (editBooking.payment_at ? formatDateTime(editBooking.payment_at) : formatDateTime(editBooking.created_at))
                                    )}
                                </p>
                            </div>
                        </div>
                        <div className='booking-form__buttons'>
                            {/* Кнопка добавления гостя */}
                            <button type="button" className='button-booking button-booking-small' onClick={addGuest}>
                                Добавить гостя
                            </button>
                        </div>
                        <div className='booking-form__guests'>
                            {guests.map((guest, index) => (
                            <div key={index} className='booking-form__guests___guest'>
                                <div className="system-input-box">
                                    <label>Полное имя #{(1+index)}</label>
                                    <input
                                        required
                                        placeholder="Имя Фамилия"
                                        value={guest.full_name}
                                        type="text"
                                        id={`full_name_${index}`}
                                        name={`full_name_${index}`}
                                        onChange={(e) => handleGuestChange(index, 'full_name', e.target.value)}
                                        />
                                </div>
                                <div className="system-input-box">
                                    <label>Серийный номер паспорта</label>
                                    <input
                                        required
                                        placeholder="Серийный номер паспорта"
                                        value={guest.passport_serial_number}
                                        type="text"
                                        id={`passport_serial_number_${index}`}
                                        name={`passport_serial_number_${index}`}
                                        onChange={(e) => handleGuestChange(index, 'passport_serial_number', e.target.value)}
                                        />
                                </div>
                                {guests.length > 1 && (
                                    <button type="button" className='button-booking button-booking-small' onClick={() => removeGuest(index)}>
                                        Удалить гостя
                                    </button>
                                )}
                            </div>
                            ))}
                        </div>
                        <div className="system-input-box">
                            <label>Телефон</label>
                            <input required placeholder="Введите номер тел." value={editBooking.phone_number} type="telephone" name="phone_number" onChange={handleInputChange} />
                        </div>
                        <div className="system-input-box">
                            <label>Почта</label>
                            <input required placeholder="Введите почту" value={editBooking.email} type="telephone" name="email" onChange={handleInputChange} />
                        </div>
                        <div className="system-column">
                            <div className="system-input-box">
                                <label>Начало брони</label>
                                <input required value={editBooking.available_from} placeholder="Заезд" type="date" name='available_from' readOnly disabled />
                            </div>
                            <div className="system-input-box">
                                <label>Окончание брони</label>
                                <input required value={editBooking.available_to} placeholder="Выезд" type="date" name='available_to' readOnly disabled />
                            </div>
                        </div>
                        {editBooking.status !== 0 && editBooking.type !== 0 &&
                        <div className="system-input-box system-address">
                            <label>Тип номера</label>
                            <div className="system-column">
                                <div className="system-select-box">
                                    <select required value={editBooking.room_id || ''} readOnly disabled name='room_id'>
                                        {rooms && rooms.length > 0 ? (
                                            rooms.map((room) => (
                                                <option key={room.id} value={room.id}>{room.translations.room_type[language]}</option>
                                            ))
                                        ) : (
                                            <option value="">Нет номеров</option>
                                        )}

                                    </select>
                                </div>
                                <input placeholder={selectedPrice || "Цена не доступна"} disabled type="text" />
                            </div>
                        </div>
                        }
                        <div className='flex-btns'>
                            <button type='button' className="system-booking-table-btn system-booking-table-btn-cancel" onClick={onClose}>Закрыть</button>
                            <button type='button' className="system-booking-table-btn system-booking-table-btn-view" onClick={() => sendData()}>Сохранить</button>
                        </div>
                        <span className='error-message'>{messageEditData}</span>
                    </form>
                </div>
            </section>
        </div>
    );
}

export default BookingEdit;