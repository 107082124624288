import React, { useState } from 'react';
import '../../styles/css/index.css';
import "../../styles/scss/ReviewsComponent.scss";

function Card({ review }) {
    const language = localStorage.getItem('language') || 'tm';
    
    const [isReviewExpanded, setIsReviewExpanded] = useState(false);
    const [isAnswerExpanded, setIsAnswerExpanded] = useState(false);

    const toggleReviewExpand = () => {
        setIsReviewExpanded(!isReviewExpanded);
    };

    const toggleAnswerExpand = () => {
        setIsAnswerExpanded(!isAnswerExpanded);
    };

    // Разделяем текст отзыва на строки
    const previewLengthMessage = 100;
    const previewLengthAnswer = 60;

    const reviewPreviewText = review.message.substring(0, previewLengthMessage);
    const reviewHasMore = review.message.length > previewLengthMessage;

    const answerPreviewText = review.answer ? review.answer.substring(0, previewLengthAnswer) : '';
    const answerHasMore = review.answer ? review.answer.length > previewLengthAnswer : false;

    const langs = {
        'ru': {
          'answer': "Ответ",
          'readFullReview': "Читать весь отзыв",
          'readFullAnswer': "Читать весь ответ",
          'hideFull': "Скрыть",
        },
        'tm': {
          'answer': "Jogap",
          'readFullReview': "Oka",
          'readFullAnswer': "Oka",
          'hideFull': "Gizle",
        },
        'en': {
          'answer': "Answer",
          'readFullReview': "Read the full review",
          'readFullAnswer': "Read the full answer",
          'hideFull': "Hide",
        }
      };

    return (
        <div className="review-card">
            <h3>{review.name}</h3>
            <div className="review-content">
                <p className={isReviewExpanded ? 'expanded' : ''}>
                    {isReviewExpanded ? review.message : reviewPreviewText}
                    {reviewHasMore && !isReviewExpanded && '...'}
                </p>
                {reviewHasMore && (
                    <button onClick={toggleReviewExpand} className="read-more-button">
                        {isReviewExpanded ? langs[language].hideFull : langs[language].readFullReview}
                    </button>
                )}
            </div>
            <small>{review.date}</small>
            {review.answer && (
                <div className="hotel-response">
                    <strong>{langs[language].answer}:</strong>
                    <div className="review-content">
                        <p className={isAnswerExpanded ? 'expanded' : ''}>
                            {isAnswerExpanded ? review.answer : answerPreviewText}
                            {answerHasMore && !isAnswerExpanded && '...'}
                        </p>
                        {answerHasMore && (
                            <button onClick={toggleAnswerExpand} className="read-more-button">
                                {isAnswerExpanded ? langs[language].hideFull : langs[language].readFullAnswer}
                            </button>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Card;
