import React, { useState } from 'react';
import { apiUrl } from '../../../config'
import { fetchData } from '../../../utils/api';
import LanguageChanger from '../../../utils/modules/LanguageChanger';


const RoomNew = ({ onClose }) => {
    const [messageNewData, setMessageNewData] = useState([])
    const [newImages, setNewImages] = useState([]);
    const [newRoom, setNewRoom] = useState({
        'room_type': '',
        'count': '',
        'count_total': '',
        'description': '',
        'additional_info': '',
        'images': [],
        'price_summer': '',
        'price_winter': '',
        'capacity': '',
        'rooms_count': '',
        'amenities': []
    });
    // Добавление нового удобства
    const handleAddAmenity = () => {
        setNewRoom((prevRoom) => ({
            ...prevRoom,
            amenities: [...prevRoom.amenities, ''], // Добавляем пустую строку в массив
        }));
    };
    const handleAmenityChange = (index, value) => {
        setNewRoom((prevRoom) => {
            const updatedAmenities = [...prevRoom.amenities]; // Копируем текущий массив
            updatedAmenities[index] = value; // Обновляем элемент по индексу
            return { ...prevRoom, amenities: updatedAmenities }; // Возвращаем обновлённый объект
        });
    };
    // Удаление удобства
    const handleRemoveAmenity = (index) => {
        setNewRoom((prevRoom) => {
        const updatedAmenities = prevRoom.amenities.filter((_, i) => i !== index);
            return { ...prevRoom, amenities: updatedAmenities };
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewRoom((prevRoom) => ({ ...prevRoom, [name]: value }));
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files); // Преобразуем FileList в массив
        if ((files.length + newRoom.images.length) > 5) {
            setMessageNewData(`Можно загрузить максимум 5 фото. Загружено ${newRoom.images.length}`); // Ограничение количества
            e.target.value = ''
            return;
        }

        const filePreviews = files.map((file) => ({
            name: file.name,
            preview: URL.createObjectURL(file),  // Создание временного URL для превью
        }));

        setMessageNewData([]) // Очистка состояния ошибки
        setNewImages(filePreviews)
        setNewRoom({ ...newRoom, images: files }); // Сохраняем файлы в состояние
    };

    const sendData = async (roomData) => {
        const formData = new FormData();
        // Добавляем текстовые данные
        for (const key in roomData) {
            if (key !== 'images') {
                formData.append(key, roomData[key]);
            }
        }
        // Добавляем файлы
        roomData.images.forEach((file, index) => {
            formData.append('images', file);  // Используем одно и то же имя 'images'
        });

        const token = localStorage.getItem('accessToken');
        const data = await fetchData(`${apiUrl}/api/sys/add-new-room`, "POST", token, {body: formData}, null)
        setMessageNewData(data.request.message)
    }

    return (
        <div className='system-background-hide'>
            <section className="system-container system-container-form">
                <div className='system-container__inner'>
                    <header className="system-header">Новый тип номера</header>
                    <form className="system-form">
                        <div className="system-input-box">
                            <label htmlFor="language">Язык</label>
                            <select id="language" disabled>
                                <option value="ru" defaultChecked>RU</option>
                            </select>
                        </div>
                        <div className="system-input-box">
                            <label>Тип номера</label>
                            <input required placeholder="Строка" value={newRoom.room_type} type="text" name='room_type' onChange={handleInputChange} />
                        </div>
                        <div className="system-input-box">
                            <label>Общедоступные номера (количество)</label>
                            <input required placeholder="Общедоступные номера" value={newRoom.count} type="number" name='count' onChange={handleInputChange} />
                        </div>
                        <div className="system-input-box">
                            <label>Описание</label>
                            <textarea placeholder="Описание" value={newRoom.description ? newRoom.description : '' } type="text" name='description' onChange={handleInputChange}>
                            </textarea>
                        </div>
                        <div className="system-input-box">
                            <label>Дополнительная информация</label>
                            <textarea placeholder="" value={newRoom.additional_info ? newRoom.additional_info : '' } type="text" name='additional_info' onChange={handleInputChange}>
                            </textarea>
                        </div>
                        <div className="system-input-box">
                            <label>Добавить фото (максимум 5)</label>
                            <input
                            type="file"
                            name="images"
                            accept="image/*"
                            onChange={handleFileChange}
                            multiple
                            />
                        </div>
                        <div className="system-input-box">
                            <label>Все номера (количество)</label>
                            <input required placeholder="Все номера" value={newRoom.count_total} type="number" name='count_total' onChange={handleInputChange} />
                        </div>
                        <div className="system-input-box">
                            <label>Цена, летний сезон</label>
                            <input required placeholder="Число" value={newRoom.price_summer} type="number" name='price_summer' onChange={handleInputChange} />
                        </div>
                        <div className="system-input-box">
                            <label>Цена, зимний сезон</label>
                            <input required placeholder="Число" value={newRoom.price_winter} type="number" name='price_winter' onChange={handleInputChange} />
                        </div>
                        <div className="system-input-box">
                            <label>Максимальное количество гостей, которые могут разместиться в номере</label>
                            <input required placeholder="Число" value={newRoom.capacity} type="number" name='capacity' onChange={handleInputChange} />
                        </div>
                        <div className="system-input-box">
                            <label>Количество комнат в номере</label>
                            <input required placeholder="Число" value={newRoom.rooms_count} type="number" name='rooms_count' onChange={handleInputChange} />
                        </div>
                        {/* <div className="system-input-box">
                            <label>Услуги</label>
                            <button type="button" className='system-booking-table-btn system-booking-table-btn-view' onClick={handleAddAmenity}>Добавить услугу</button>
                            {newRoom.amenities ? (
                                newRoom.amenities.map((amenity, index) => (
                                    <div key={index} className="amenity-item">
                                        <input
                                            placeholder="Удобство"
                                            value={amenity}
                                            type="text"
                                            onChange={(e) => handleAmenityChange(index, e.target.value)} />
                                        <button type="button" className='system-booking-table-btn system-booking-table-btn-cancel' onClick={() => handleRemoveAmenity(index)}>Удалить</button>
                                    </div>
                                ))
                            ) : null}
                        </div> */}
                        <div className='flex-btns'>
                            <button type='button' className="system-booking-table-btn system-booking-table-btn-cancel" onClick={onClose}>Закрыть</button>
                            <button type='button' className="system-booking-table-btn system-booking-table-btn-view" onClick={() => sendData(newRoom)}>Сохранить</button>
                        </div>
                        {messageNewData ? (
                            <span className='error-message'>{messageNewData}</span>
                        ) : (null)}
                    </form>
                </div>

                {newImages.length ? (
                <div className="system-container__inner system-container__inner-images">
                        <div className="system-container__inner__images">
                            <header className="system-header">Новые</header>
                            {newImages.map((image, index) => (
                                <div className='system-header__edit-image-new' key={index}>
                                    <img
                                        src={image.preview} 
                                        alt={`Room ${index}`} 
                                    />
                                </div>
                            ))}
                        </div>
                </div>
                ) : null}
            </section>
        </div>
    );
}

export default RoomNew;